import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation , FreeMode} from 'swiper';
import 'styles/_fontsFamily.module.scss';
import styles from './product-carousels-index.module.scss';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { addRemoveFavoriteItem } from 'components/shared/productFavFunctions';
import BoardsListTooltip from 'components/shared/boardsListTooltip';
import { API, ProductGrayImageId } from '@/constants/global-config';
import Link from 'next/link';
import 'moment-timezone';
import moment from 'moment';
import { checkProductOnlyForSale, checkPurchaseOnly, isUsedProdPriceChangeForNonSub, saveViewProductActivityCarosel, capitializeString } from 'components/shared/globalFunctions';
import { useSelector } from 'react-redux';
import { useResponsive } from '@/libraries/responsive';

interface IProps {
    sliderName?: string|undefined;
    sliderData?: any;
    customerBoards: any;
    isLoopAllowed: boolean;
    onSwiperSlideChange: (e:any)=>any;
    currentSwiperSlide: any;
    swiperSliderName:string
    setSignupModal: (e:any)=>any
}

var alreadyViewProductArr = []
const ProductsCarouselsIndex: React.FC<IProps> = ({sliderName,sliderData,customerBoards,isLoopAllowed,onSwiperSlideChange,currentSwiperSlide, swiperSliderName,setSignupModal}) => {

  const customer = useAppSelector((state) => state.customer);
  const systemSettings = useSelector((state) => state.systemSettings);
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsive();

  function calculatedProductPrice(item: any) {

    let isUsedItem = false;
    let has_subscription = false;
    if (customer.customer != 'not_login') {
      has_subscription = customer.customer.has_subscription;
    }
    if (!isUsedItem && item.sizesNew) {
      Object.keys(item.sizesNew).map(function (newSize, index) {
        Object.keys(item.sizes).map(function (totalSize, ind) {
          if (newSize == totalSize && item.sizes[totalSize] - item.sizesNew[newSize] > 0) {
            isUsedItem = true;
          }
        });
      });
    }

    var date = (item.created * 1000)._d;
    let created_at = moment(date, 'MM-DD-YYYY HH:mm').utc().format('YYYY-MM-DD HH:mm');
    created_at = moment(created_at)._d;
    let discountedPrice = 0,
      isDiscount = false;
    let hidden_price = item.retail;
    let subscribe_discount_price = item.retail;
    if (item.useitem_discount != null && item.useitem_discount != undefined) {
      hidden_price = Math.floor(item.retail - (item.retail * item.useitem_discount) / 100);
    }
    let isPurcahseOnly = checkPurchaseOnly(item);
    let onlyForSale = checkProductOnlyForSale(item)
    let percentDiscount = 0;
    if (!isPurcahseOnly && item.useitem_discount != null && item.useitem_discount > 0 && isUsedItem) {
      percentDiscount = item.useitem_discount;
      discountedPrice = item.retail * (item.useitem_discount / 100);
      if (discountedPrice > 0) isDiscount = true;
    }
    if (isPurcahseOnly && item.newitem_discount != null && item.newitem_discount > 0) {
      percentDiscount = item.newitem_discount
      discountedPrice = item.retail * (item.newitem_discount / 100)
      if (discountedPrice > 0) isDiscount = true
    }
    discountedPrice = Math.floor(item.retail - discountedPrice);
    if (has_subscription) {
      if (item.discount != null && item.discount > 0)
        percentDiscount = item.discount;
      subscribe_discount_price = Math.floor((item.retail - ((item.retail * item.discount) / 100)));
    }
    if ((customer.customer != 'not_login' && !customer.customer.has_subscription) || customer.customer == 'not_login') {
      if (!isPurcahseOnly && isUsedItem && item.useitem_discount != null) {
        let isChangeEligible = isUsedProdPriceChangeForNonSub(item, systemSettings.systemSettings);
        if (isChangeEligible == true) {
          percentDiscount = item.discount;
          discountedPrice = Math.floor(item.retail - (item.retail * item.discount) / 100);
          if (discountedPrice > 0) isDiscount = true;
        }
      }
    } else {
      percentDiscount = item.discount;
      discountedPrice = Math.floor(item.retail - (item.retail * item.discount) / 100);
      if (discountedPrice > 0) isDiscount = true;
    }
    return (
      <>
        <div className={styles.card_price_div}>
          {
            has_subscription && item.retail > 0 ? <> {item.retail > subscribe_discount_price ? <> <span className={`${styles.collection_product_title_retail}  ${styles.linethrough_price} `}>Retail ${Math.floor(item.retail)}</span> <span className={`${styles.price} pe-2`}>  | ${Math.floor(subscribe_discount_price)} to buy</span> </> : <span className='text_normal'>Retail ${Math.floor(item.retail)}</span>} </> :
            <span className='text_normal'>Retail ${Math.floor(item.retail)} <span style={{fontFamily: 'Poppins Medium'}}>| $0 to rent</span></span>
          }
        </div>
      </>
    );
  }

  function vlink(vendor_handle: string, handle: string) {
    let vlink = vendor_handle != null && vendor_handle != '' && vendor_handle != undefined ? vendor_handle : 'vendor';
    vlink = '/product/' + vlink + '/' + handle;
    return vlink;
  }

  const toggleFavorite = (e: any, id: any, isLogin: any) => {
    if (isLogin != 'not_login') {
      let selector = e.target.classList;
      if (selector.toString().indexOf('far') > -1) {
        // if (e.target.parentElement) e.target.parentElement.classList.add('filled');
        selector.remove('far');
        selector.add('fas');
        dispatch(addRemoveFavoriteItem(parseInt(id), 1, true));
        // props._addRemoveFavoriteItem(parseInt(id), 1, true);
      } else if (screen.width > 767) {
        selector.remove('fas');
        selector.add('far');
        if (e.target.parentElement) e.target.parentElement.classList.remove('filled');
        // props._addRemoveFavoriteItem(parseInt(id), 0, true);
        dispatch(addRemoveFavoriteItem(parseInt(id), 0, true));
        var selectedBoards = [].slice.call(e.target.parentElement.children[1].children[0].children);
        selectedBoards.filter((customerBoards: any) => {
          if (customerBoards.classList) {
            customerBoards.classList.remove('selected');
          }
        });
      }
      if (screen.width <= 767) {
        let boardsList = document.querySelectorAll('.newBoard-tooltip')
        boardsList.forEach((val, ind) => {
          if (boardsList[ind]) {
            boardsList[ind].style.display = "none"
          }
        })
        let boardId = 'board_' + id
        if (document.getElementById(boardId)) {
          document.getElementById(boardId).style.display = 'block'
          if (document.querySelector('.backdrop-boardTooltip'))
            document.querySelector('.backdrop-boardTooltip').style.display = 'block'
        }
      }
    }else{
      setSignupModal(true)
    }
  };

  return (
    <div className={styles.card_conatainer}>
    <Swiper
        modules={[Navigation,FreeMode]}
        navigation={true}
        className={`${styles.swiper}`}
        initialSlide={currentSwiperSlide}
        loop={false}
        onSlideChange={(e) => { 
          onSwiperSlideChange(e);
          alreadyViewProductArr =  saveViewProductActivityCarosel(e, sliderData, 5, 3, alreadyViewProductArr, customer.customer, swiperSliderName  , 5)
        }}
        breakpoints={{
        1: {
            slidesPerView: sliderData?.length <= 2 ? sliderData?.length : 2.7,
            spaceBetween: 10,
            initialSlide: 0,
            freeMode: {
              enabled: true,
              sticky: false
            }
        },
        768: {
          slidesPerView: sliderData?.length <= 4 ? sliderData?.length : 5,
          spaceBetween: 25,
          slidesPerGroup: sliderData?.length <= 4 ? sliderData?.length : 5
        },
        }}
        simulateTouch={true}
    >
        {sliderData?.length > 0 &&
        sliderData?.map((item: any, index: any) => {

            let isImageOld = true;
            if (
            item?.tags.filter((items: any) => {
                if (items == ProductGrayImageId) return true;
                else return false;
            }).length > 0
            ) {
            isImageOld = false;
            }

            return (
            

                <SwiperSlide key={index}>
                <div className={`${styles.swiper_body}`}>
                    <div className={item?.is_favorite ? 'favBox sliderMoreIn ' : 'favBox sliderMoreIn'}>
                    <i className={item?.is_favorite ? `fas fa-heart ${isImageOld ? 'pink-heart' : 'pink-heart-new'}` : `far fa-heart ${isImageOld ? 'pink-heart' : 'pink-heart-new'}`} onClick={(e) => toggleFavorite(e, item?.id, customer.customer)} onTransitionEnd={(e) => toggleFavorite(e, item?.id, customer.customer)} id={'product_' + item?.id} aria-hidden="true"></i>
                    {customer.customer != 'not_login' && <BoardsListTooltip boards={customerBoards} item={item} favoriteBoards={item?.favorite_boards} comp={'products'} />}
                    </div>
                    <Link href="/product/[...handle]" as={vlink(item?.vendor_handle, item.handle)}>
                    <div className={`${styles.swiper_card}`} id={index == 0 ? "how-it-works-click" : "dummyid" + index} >
                        <img className={styles.card_img} src={API.FASHION_PASS_PRODUCT_IMAGE_URL + item?.images[0] + '?profile=b2x3'} alt={`${capitializeString(item?.attributes?.external_color?.[0]?.name)} ${capitializeString(item?.title)} by ${item?.vendor} Front View`} />
                    </div>
                    </Link>
                    <div className={styles.prod_detail}>
                      <p className={`${styles.card_brand}`}> {item?.vendor} </p>
                      <p className={`${styles.card_name}`}> {'' + item?.title.toLowerCase()} </p>
                      { customer.customer != 'not_login' && customer.customer.has_subscription == true ?
                        <div className={styles.available_size}>
                        {
                            Object.keys(item.sizes).map(function (key: string, index: number) {
                            if (item.sizes[key] > 0 && key != 'NONE') {
                                return (
                                <span className={`${styles.card_size}`} key={index}>
                                    {key}
                                </span>
                                )
                            }
                            })
                        }
                        </div> : ''
                      }
                      <div className={`${styles.price_div}`}>{calculatedProductPrice(item)}</div>
                    </div>
                </div>
                </SwiperSlide>
            
            );
        })}
    </Swiper>
    </div>
  )
};
export default ProductsCarouselsIndex;
