import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import Loader from 'components/shared/loadingComp';
import style from 'styles/loading_popup.module.scss'

interface IProps {
  isOpen: boolean;
}

const HoldingPopup: React.FC<IProps> = ({isOpen}) => {

  return (
    <div >
      <Modal isOpen={isOpen} centered modalClassName={style.modal_style} backdropClassName={style.backdrop} className={style.modal_container} contentClassName={style.loading_container} size='lg'>
        <ModalBody>
          <div >
            <p className={style.heading}>Redirecting your hold request</p>
            <Loader small={true} />
          </div>
        </ModalBody>
      </Modal>
  </div>
  )
}

export default HoldingPopup