import React, { useEffect, useState, useRef } from 'react';
import styles from './current-order-index.module.scss'
import Gconfig from 'globalconfig';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false },);
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { triggerCartPopper } from "redux/actions/cartActions";
import { addProductToCartFromFunctionComp } from "components/shared/cartFunctions"
import Link from 'next/link';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import TransferNextOrderPopup from 'components/shared/transferNextOrderPopup';
import { getSubscribedLastOrderInfo, capitializeString, getRangeBetweenTwoNumbers, checkProductOnlyForSale,discountAndPriceCalculation, checkForTransferItem, checkForShipment } from 'components/shared/globalFunctions';
import WaveLoader from 'components/waveLoader';
import jsCookie from 'js-cookie';
import { lastRentalReturnOrder } from '@/redux/actions/lastRentalReturnOrderActions'

interface IProps {}

const CurrentOrderHomeComp: React.FC<IProps> = ({ }) => {

  const [lastOrderInfo, setLastOrderInfo] = useState(null);
  const [openOrderTransferedPopup, setOpenOrderTransferedPopup] = useState(false);
  const [transferItemInfo, setTransferItemInfo] = useState(null);
  const [msg, setMsg] = useState({ type: "", msg: "" })
  const [isLoading, setIsLoading] = useState(true)
  const [prevTransferInfoForNewTransferItem, setPrevTransferInfoForNewTransferItem] = useState(null)
  const [isServerSide, setIsServerSide] = useState(true)
  const [cancelTrasferPopup, setCancelTrasferPopup] = useState(false);
  const [rentalOrderCountThisMonth, setRentalOrderCountThisMonth] = useState(0);
 
  const customerData:any = useAppSelector((state) => state.customer);
  const cart = useAppSelector((state) => state.cart);
  const systemSettings = useAppSelector((state) => state.systemSettings);
  
  const dispatch = useAppDispatch();

  useEffect(() => { 
    setIsServerSide(false)
    getLastRentalOrderInfo()
    if(customerData.customer != "not_login" && customerData.customer?.has_subscription) {
      let customerAttribute = customerData.customer?.attributes
      let orderCount = 0
      if(customerAttribute && customerAttribute?.attributes && customerAttribute?.attributes?.length > 0 ) {
          let attributeArr = customerAttribute?.attributes
          attributeArr.map((attr:any, ind:any) => {
              if(attr && attr.name?.indexOf('partial-first-shipment') > -1 && orderCount < 1) {
                  orderCount = 1
              }
              if(attr && attr.name?.indexOf('partial-second-shipment') > -1 && orderCount < 2) {
                  orderCount = 2
              }
              if(attr && attr.name?.indexOf('partial-third-shipment') > -1 && orderCount < 3) {
                  orderCount = 3
              }
          })
      }
      setRentalOrderCountThisMonth(orderCount)
  }
  }, []);

  async function getLastRentalOrderInfo() {
    let res = await getSubscribedLastOrderInfo(customerData.customer.customer_id)
    dispatch(lastRentalReturnOrder(res))
    setIsLoading(false)
    if(res.success && res.result) {
      if(res.result.items && res.result.items.length > 0) {
        let sortedItemArrTransfer:any = []
        let sortedItemArrTransferAcc:any = []
        let sortedItemArrCloth:any = []
        let sortedItemArrAcc:any = []
        res.result.items.map((item:any) => {
          if(item.tags.indexOf('transferfrom:') > -1 && item.tags.indexOf('transferfrom:'+res.result.orderid) == -1 ){
            if(item?.product?.product_catagory == 1) {
              sortedItemArrTransfer.push(item)
            } 
            else {
              sortedItemArrTransferAcc.push(item)
            }
          }
          else if(item?.product?.product_catagory == 1) {
            sortedItemArrCloth.push(item)
          }
          else {
            sortedItemArrAcc.push(item)
          }
        })
        sortedItemArrTransfer = sortedItemArrTransfer.concat(sortedItemArrTransferAcc)
        sortedItemArrTransfer = sortedItemArrTransfer.concat(sortedItemArrCloth)
        sortedItemArrTransfer = sortedItemArrTransfer.concat(sortedItemArrAcc)
        res.result.items = sortedItemArrTransfer
      }
      setLastOrderInfo(res.result)
    }
  }

  useEffect(()=>{
    let cancelTransferCookie = jsCookie.get('cancelTransferCookie')
    if(cancelTransferCookie != undefined){
      let orderId = cancelTransferCookie?.split('-')[0]
      let skuId = cancelTransferCookie?.split('-')[1]
      let lastOrderItem = []
      if(lastOrderInfo?.items?.length > 0){
        lastOrderItem = lastOrderInfo?.items?.filter(function (prod) { return prod.skuid == skuId})
      }
      if(lastOrderInfo != null && lastOrderInfo?.orderid == orderId && lastOrderItem?.length > 0 ){
        if(document.getElementById(skuId)){
          setTimeout(()=>{
            document.getElementById(skuId)?.click();
            jsCookie.remove('cancelTransferCookie')
          },100)
        }
      }
    }
  },[lastOrderInfo])

  function showTranserPopup(productInfo:any, transferInfo:any, cancelTransfer: boolean) {
    if(cancelTransfer == true){
      setCancelTrasferPopup(true)
    }
    setTransferItemInfo(productInfo)
    setOpenOrderTransferedPopup(true)
    setPrevTransferInfoForNewTransferItem(transferInfo)
  }

  function addAlreadyRentItemAsPurchaseToCart(item:any) {
    let extra = ""
    if(item.product.tags.indexOf('garment-bag') >- 1) { 
      extra = 'jacket'
    }
    
    let cartObj = {
      customer_id: customerData.customer.customer_id,
      product_id: item.product.product_id,
      size: item.sku.size == 'NONE' ? '' : item.sku.size,
      cartItemType: 2,
      newItem: false, // ye update krna hy discuss kr k
      extraParameters: extra,
      sku_id: item.sku.sku_id,
      isShoes: false,
      customer_email: customerData.customer.email, 
      sku_code: item.sku.sku,
      IsLogin: true,
      is_ignore_available_quantity : true
    }

    dispatch(addProductToCartFromFunctionComp(() => dispatch(triggerCartPopper(true)), cartObj, setMsg, true, 1))

  }

  function prodBoxCart(item:any) {
    let onlyForSale = checkProductOnlyForSale(item.sku?.product)
    let obj = discountAndPriceCalculation(customerData.customer ,item.sku, onlyForSale, systemSettings.systemSettings, true);
    let orignalprice =  obj.price
    let orignalDiscount =  obj.discount

    return (
        <div className={styles.cart_item_prod_box}>
            <img src={Gconfig.productImageUrl + item.sku?.product?.product_thumbnail} alt="" />
            <div className={`${styles.prod_info_box} ${styles.prod_info_box_current_order_item}`}>
              <div className={styles.product_description_hover}>
                <p className={styles.prod_title} >{capitializeString(item.sku.product.product_title)}</p>
                <p className={styles.prod_vendor} >{item.sku.vendor}</p>
                {item.sku.size != "NONE" ? <p className={styles.prod_size} >Size: {item.sku.size}</p> : "" }
                <p className={styles.prod_retail}> Retail: <span> ${item.sku.retail} </span> { orignalDiscount > 0 ? ` (${orignalDiscount}% OFF) ` : ''} </p>
                <p className={styles.member_price}> Member Purchase Price: <span> ${orignalprice} </span>  </p>
              </div>
            </div>
        </div> 
    )
  }
  
  function prodBoxLastRentalOrder(item:any, isFromCurrentlyOrdering: boolean) {
    let onlyForSale = checkProductOnlyForSale(item.product)
    let obj = discountAndPriceCalculation(customerData.customer ,item, onlyForSale, systemSettings.systemSettings, true);
    let orignalprice =  obj.price
    let orignalDiscount =  obj.discount

    let TransferInfo =  checkForTransferItem(lastOrderInfo, lastOrderInfo?.items, customerData.customer, systemSettings.systemSettings, lastOrderInfo?.orderid)
    
   
    let product = item.product
    let vlink = (item && item.vendor_handle != null && item.vendor_handle != "" && item.vendor_handle != undefined) ? item.vendor_handle : 'vendor';
    vlink = "/product/" + vlink + "/" + product.product_handle;
   
    let appendHeightClass = ""
    if(item?.tags?.indexOf('transfer') > -1){
      if(item?.tags?.indexOf('transferfrom:'+lastOrderInfo?.orderid) > -1){
        appendHeightClass = "1"
      }
      else{
        appendHeightClass = "2"
      }
    }

    return (
      <>
      <MediaQuery minWidth={768}>
      {/* ${appendHeightClass == '1' ? styles.last_order_prod_one :  appendHeightClass == '2' ? styles.last_order_prod_two : ""} */}
          <div className={`${styles.last_order_prod} `}> 
            <img  src={Gconfig.productImageUrl + item.product?.product_thumbnail} alt="" />

            {/* {item?.tags?.indexOf('transfer') > -1 ? 
              item?.tags?.indexOf('transferfrom:'+lastOrderInfo?.orderid) > -1 ?<>
              <button id={item.skuid} className={`${styles.btn_transfer_hover} ${styles.btn_transferring_hover} ${styles.btn_tranfering_change} d-flex ${lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? '' : 'cursor-default'} `} type="button"  > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transferring</span> </button>
              <p className={styles.cancel_tranfer_btn} onClick={()=>{ lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? showTranserPopup(item, TransferInfo, true) : {} }}>cancel transfer</p>
              </>
              :
              <span className={styles.transfering_tag}> <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span> Transferred </span> </span> 
            :  ""
            } */}
              


              <Link href={vlink}>
                <div className={`${styles.prod_info_box} ${styles.prod_info_box_last_order_item}`}>
                    <div className={styles.product_description_hover}>
                      <p className={styles.prod_title} >{capitializeString(item.product.product_title)}</p>
                      <p className={styles.prod_vendor} >{item.sku.vendor}</p>
                      {item.sku.size != "NONE" ? <p className={styles.prod_size} >Size: {item.sku.size}</p> : "" }
                      <p className={styles.prod_retail}> Retail: <span> ${item.sku.retail} </span> { orignalDiscount > 0 ? ` (${orignalDiscount}% OFF) ` : ''} </p>
                      <p className={styles.member_price}> Member Purchase Price: <span> ${orignalprice} </span>  </p>
                    </div>
                    {/* <button type="button" className={styles.btn_purchase} onClick={()=>{ addAlreadyRentItemAsPurchaseToCart(item) }} > <label> Purchase </label> </button> */}
                  {/* {TransferInfo.isTransferItemAvailable == true && ((TransferInfo.isClothingTransferAllow && item.product.product_catagory == 1) || (TransferInfo.isAccessoryTransferAllow && item.product.product_catagory != 1))  && isFromCurrentlyOrdering == false && lastOrderInfo != null && item?.tags?.indexOf('transfer') == -1  ? <button className={styles.btn_transfer_hover} type="button" onClick={()=>{ showTranserPopup(item, TransferInfo) }} > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transfer</span> </button> : "" } */}
                </div>
              </Link>
               
               {item?.tags?.indexOf('bonus_item') > -1  ? <span className={`${styles.bonus_tag}  ${item?.tags?.indexOf('transfer') > -1 ? styles.bonus_tag_move_up : ''}`}>  <span> BONUS RENTAL </span> </span>  : "" }
               
               { 
                ((TransferInfo.isTransferItemAvailable == true && ((TransferInfo.isClothingTransferAllow && item.product.product_catagory == 1) || (TransferInfo.isAccessoryTransferAllow && item.product.product_catagory != 1))  && isFromCurrentlyOrdering == false && lastOrderInfo != null && item?.tags?.indexOf('transfer') == -1) || item?.tags?.indexOf('transfer') > -1) ?
                    <div style={{ marginTop:"10px" }}>
                      {TransferInfo.isTransferItemAvailable == true && ((TransferInfo.isClothingTransferAllow && item.product.product_catagory == 1) || (TransferInfo.isAccessoryTransferAllow && item.product.product_catagory != 1))  && isFromCurrentlyOrdering == false && lastOrderInfo != null && item?.tags?.indexOf('transfer') == -1  ? <button className={styles.btn_transfer_hover} type="button" onClick={()=>{ showTranserPopup(item, TransferInfo, false) }} > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transfer</span> </button> : "" }
                      {
                        item?.tags?.indexOf('transfer') > -1 ? 
                            item?.tags?.indexOf('transferfrom:'+lastOrderInfo?.orderid) > -1 ?
                            <>
                              <button id={item.skuid} className={`${styles.btn_transfer_hover} ${styles.btn_transferring_hover} ${styles.btn_tranfering_change} d-flex ${lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? '' : 'cursor-default'} `} type="button"  > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transferring</span> </button>
                              <p className={styles.cancel_tranfer_btn} onClick={()=>{ lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? showTranserPopup(item, TransferInfo, true) : {} }}> cancel transfer </p>
                            </>
                            :
                            <span className={styles.transfering_tag}> <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span> Transferred </span> </span> 
                          :  ""
                      }
                    </div> 
                : "" }
              
          </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div className={styles.last_order_prod}>
              <div className='position-relative'>
                <Link href={vlink}>
                  <img  src={Gconfig.productImageUrl + item.product?.product_thumbnail} alt="" />
                </Link>

                  {item?.tags?.indexOf('bonus_item') > -1  ? <span className={`${styles.bonus_tag}`}>  <span> BONUS RENTAL </span> </span>  : "" }

              </div>
              <Link href={vlink}>
                <div className={styles.product_description_hover}>
                  <p className={styles.prod_vendor} >{item.sku.vendor}</p>
                  <p className={styles.prod_title} >{capitializeString(item.product.product_title)}</p>
                   <p className={styles.prod_size} > {item.sku.size != "NONE" ? `Size: ${item.sku.size}` : ''}</p>
                  {
                    orignalDiscount >= 0 ? <p className={styles.prod_retail}> <span className={styles.strike}> Retail ${item.sku.retail} </span> <span className={styles.member_price}> | ${orignalprice} to buy </span> </p>
                    : <p className={styles.prod_retail}> Retail: <span> ${item.sku.retail} </span> </p>
                  }
                </div>
              </Link>
                {item?.tags?.indexOf('transfer') > -1 ? 
                  item?.tags?.indexOf('transferfrom:'+lastOrderInfo?.orderid) > -1 ?
                  <>
                  <button id={item.id} className={`${styles.btn_transfer_hover} ${styles.btn_transferring_hover} ${styles.btn_tranfering_change}  d-flex`} type="button" onClick={()=>{ lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? showTranserPopup(item, TransferInfo, true) : {} }} > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transferring</span> </button>
                  <p className={styles.cancel_tranfer_btn} onClick={()=>{ lastOrderInfo?.order_tags?.indexOf('return_inspected') == -1 ? showTranserPopup(item, TransferInfo, true) : {} }}> cancel transfer </p>
                  </>
                  :
                  <span className={styles.transfering_tag}> <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span> Transferred </span> </span> 
                  :
                  TransferInfo.isTransferItemAvailable == true && ((TransferInfo.isClothingTransferAllow && item.product.product_catagory == 1) || (TransferInfo.isAccessoryTransferAllow && item.product.product_catagory != 1))  && isFromCurrentlyOrdering == false && lastOrderInfo != null && item?.tags?.indexOf('transfer') == -1  ? <button className={styles.btn_transfer_hover} type="button" onClick={()=>{ showTranserPopup(item, TransferInfo, false) }} > <img src={Gconfig.fashionpassImageStorage+'assets/img/transfer-icon.svg'}/> <span>Transfer</span> </button> : '' 
                  }
          </div>
      </MediaQuery>
      </>
    )
  }


  function updateAvailableSlotsForOrder() {
    
      if(customerData.customer == "not_login") return { "remainingSlots":[], 'totalBoxCount': 0 };

      let plan_data:any = systemSettings.systemSettings  && systemSettings.systemSettings.length > 0 && systemSettings.systemSettings.find(function (sett) { return (typeof sett != undefined && sett != undefined && sett.setting_id == Gconfig.fp_plans)});
      let plan:any = JSON.parse(plan_data.setting_value)

      if(!plan) return { "remainingSlots":[], 'totalBoxCount': 0 }

      let availablePossiblitesForPlan = {
        'socialite'   : { "clothing": parseInt(plan.socialite.clothing), "accessory": parseInt(plan.socialite.accessory), "minslots": parseInt(plan.socialite.accessory) + parseInt(plan.socialite.clothing), "maxslots": parseInt(plan.socialite.accessory) + (parseInt(plan.socialite.clothing)*2) },
        'trendsetter' : { "clothing": parseInt(plan.trendsetter.clothing), "accessory": parseInt(plan.trendsetter.accessory), "minslots": parseInt(plan.trendsetter.accessory) + parseInt(plan.trendsetter.clothing), "maxslots": parseInt(plan.trendsetter.accessory) + (parseInt(plan.trendsetter.clothing)*2) },
        'wanderlaust' : { "clothing": parseInt(plan.wanderlust.clothing), "accessory": parseInt(plan.wanderlust.accessory), "minslots": parseInt(plan.wanderlust.accessory) + parseInt(plan.wanderlust.clothing), "maxslots": parseInt(plan.wanderlust.accessory) + (parseInt(plan.wanderlust.clothing)*2) },
        'fashionpass' : { "clothing": parseInt(plan.fashionpass.clothing), "accessory": parseInt(plan.fashionpass.accessory), "minslots": parseInt(plan.fashionpass.accessory) + parseInt(plan.fashionpass.clothing), "maxslots": parseInt(plan.fashionpass.accessory) + (parseInt(plan.fashionpass.clothing)*2) },
      }

      let userPossibilites = {}
    
      if(customerData.customer && customerData.customer.subscription_name == "Wanderlust" ) 
        userPossibilites = availablePossiblitesForPlan.wanderlaust
      else if(customerData.customer && customerData.customer.subscription_name == "Platinum" )
        userPossibilites = availablePossiblitesForPlan.trendsetter
      else if(customerData.customer && customerData.customer.subscription_name == "Gold") 
        userPossibilites = availablePossiblitesForPlan.socialite
      else if(customerData.customer && customerData.customer.subscription_name == "FashionPass") {
        userPossibilites = availablePossiblitesForPlan.fashionpass
      }
  
      let totalShownBoxCount = 0

      let lastOrder = lastOrderInfo
      let lastOrderTransferedItems = 0;
      
      if(lastOrder) {
        lastOrder && lastOrder.items && lastOrder.items.map((item?:any) => {
          if(item.tags.indexOf('transferfrom:'+lastOrder.orderid) > -1 && customerData.customer.tag_list?.indexOf('CurrentlyBorrowing') == -1 ) {
            lastOrderTransferedItems = item?.product?.product_catagory == 1 ? lastOrderTransferedItems + 2 : lastOrderTransferedItems + 1
            totalShownBoxCount = totalShownBoxCount + 1
          }
          else if(customerData.customer.tag_list?.indexOf('CurrentlyBorrowing') > -1 && item.type.indexOf('Sale') == -1) {
            totalShownBoxCount = totalShownBoxCount + 1
          }
        })
      }
      
      let filledProdSlotsCount = lastOrderTransferedItems
      let slotsArr:any = []
      if(filledProdSlotsCount < userPossibilites.maxslots && customerData.customer.tag_list?.indexOf('CurrentlyBorrowing') == -1) {
        let remainingSlots = userPossibilites.maxslots - filledProdSlotsCount;
        getRangeBetweenTwoNumbers({ start: 1, end:(userPossibilites.maxslots - filledProdSlotsCount) }).map((val, ind) => {
            if((remainingSlots == 3 || remainingSlots == 2) && customerData.customer.subscription_name != 'Gold') {
                let obj = {type: 3}
                slotsArr.push(obj)
                if(remainingSlots == 3 || remainingSlots == 2) remainingSlots = remainingSlots - 2;
                else remainingSlots = remainingSlots - 1;
            }
            else if(remainingSlots == 1) {
              let obj = {type: 2}
              slotsArr.push(obj)
              remainingSlots = remainingSlots - 1;
            }
            else if(remainingSlots > 1) {
              let obj = {type: 1}
              slotsArr.push(obj)
              remainingSlots = remainingSlots - 2;
            }
        })
        totalShownBoxCount = totalShownBoxCount + slotsArr.length
        return { "remainingSlots":slotsArr, 'totalBoxCount': totalShownBoxCount }
      }
      else {
        return   { "remainingSlots":[], 'totalBoxCount': totalShownBoxCount }
      }
  }

  function renderCustomerLastOrderForPurchaseAndTransfer() {
    return(
        <div className={styles.last_order_box_container}>
          {lastOrderInfo 
          ? lastOrderInfo.items.map((item:any, ind:any) => {
            if(item?.type.indexOf('Sale') == -1) {
              return(
                <React.Fragment key={'prodBoxLastRentalOrder' + ind}>
                  <MediaQuery maxWidth={767} >
                        <SwiperSlide key={'mainbox'+ind}>
                          {prodBoxLastRentalOrder(item, false)}
                        </SwiperSlide>
                  </MediaQuery>
                  <MediaQuery minWidth={768} >
                    {prodBoxLastRentalOrder(item, false)}
                  </MediaQuery>
                </React.Fragment>
              )
            }
           })
           : ""
          }
        </div>
    )
  }

  function renderCustomerCurrentlyOrderingInfo() {
    return (
      <div className={`${styles.current_order_box_container}`}>
        
        {/* item transfered from prev order */}
        {lastOrderInfo  ? lastOrderInfo.items.map((item:any, ind:any) => {
              if(item.tags.indexOf('transferfrom:'+lastOrderInfo.orderid) > -1) {
                return(
                  <React.Fragment key={'lastOrderInfo' + ind}>
                    <MediaQuery minWidth={768}> 
                      {prodBoxLastRentalOrder(item, true)}
                    </MediaQuery>
                    <MediaQuery maxWidth={767} > 
                      <SwiperSlide key={'mainbox'+ind}>
                        {prodBoxLastRentalOrder(item, true)}
                      </SwiperSlide>
                    </MediaQuery>
                  </React.Fragment>
                )
              }
          }) : ""
        }
        
        {/* remianing slot box according to plan */}
        { updateAvailableSlotsForOrder().remainingSlots.length > 0 && updateAvailableSlotsForOrder().remainingSlots.map((val, ind) => {
           return (
              <React.Fragment key={'slot'+ind}>
              <MediaQuery minWidth={768} > 
                <div className={styles.transfer_box}>
                  <div className={styles.transfer_order_box}>
                    <div className={styles.empty_box}>
                      <div className={styles.inner_box}>
                        {checkForShipment(customerData.customer)?.shipmentNumber >= 3 ? 
                         <> 
                          <img className={styles.key_icon} src={Gconfig.fashionpassImageStorage+'assets/img/lock_vector_icon.svg?profile=a'}  />
                         </>
                         :
                         <>
                            {(val.type == 1 || val.type == 2) ? <p>{val.type == 1 ? '1 Clothing' : '1 Accessory' }</p> : <p>1 Clothing <br/> OR <br/> 2 Accessories</p> }
                          </> }
                      </div>
                    </div>
                  </div>
                </div> 
              </MediaQuery>
              <MediaQuery maxWidth={767} > 
                <SwiperSlide key={'mainbox'+ind}>
                  <div className={styles.transfer_box}>
                    <div className={styles.transfer_order_box}>
                      <div className={styles.empty_box}>
                        <div className={styles.inner_box}>
                          {checkForShipment(customerData.customer)?.shipmentNumber >= 3 ? 
                            <> 
                              <img className={styles.key_icon} src={Gconfig.fashionpassImageStorage+'assets/img/lock_vector_icon.svg?profile=a'}  />
                            </>
                            :
                            <>
                              {(val.type == 1 || val.type == 2) ? <p>{val.type == 1 ? '1 Clothing' : '1 Accessory' }</p> : <p>1 Clothing <br/> OR <br/> 2 Accessories</p> }
                            </> 
                          }
                        </div>
                      </div>
                    </div>
                  </div> 
                </SwiperSlide>
              </MediaQuery>
            </React.Fragment>
            )
          })
        }
      </div>
    
    )
  }

  function loadingCurrentlyOrderingInfo() {
    let totalBox = 5
    if(customerData.customer && customerData.customer.subscription_name == "Wanderlust" ) totalBox = 6
    else if(customerData.customer && customerData.customer.subscription_name == "Platinum" )  totalBox = 4
    else if(customerData.customer && customerData.customer.subscription_name == "Gold")  totalBox = 3
    else if(customerData.customer && customerData.customer.subscription_name == "FashionPass")  totalBox = 6

    return (
      <div className={`${styles.current_order_box_container}`}>
        {/* remianing slot box according to plan */}
        {  getRangeBetweenTwoNumbers({ start:1, end:totalBox }).map((val, ind) => {
           return (
              <React.Fragment key={'slot'+ind}>
                <MediaQuery minWidth={768} > 
                  <div className={styles.transfer_box}>
                    <div className={styles.transfer_order_box}>
                      <div className={styles.empty_box}>
                        <div className={styles.inner_box}>
                          <div style={{ height: '2rem' }}> <WaveLoader/> </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </MediaQuery>
                <MediaQuery maxWidth={767} > 
                  <SwiperSlide key={'mainbox'+ind}>
                    <div className={styles.transfer_box}>
                      <div className={styles.transfer_order_box}>
                        <div className={styles.empty_box}>
                          <div className={styles.inner_box}>
                            <div style={{ height: '2rem' }}> <WaveLoader/> </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                  </SwiperSlide>
                </MediaQuery>
              </React.Fragment>
            )
          })
        }
      </div>
    )
  }

  return (
    <>
    <div className={styles.container_order_main}>
      <div className={styles.top_head}>
          <h1> Your Current Order </h1>
          {customerData.customer  != "not_login" && customerData.customer.tag_list?.indexOf('CurrentlyBorrowing') > -1 ? 
            <p> 
                {/* <span> Your order is being processed! <br className='d-md-none'/> You'll receive an email with tracking once it ships. </span> :  */}
                <span> <span className={styles.remaining_order}> {rentalOrderCountThisMonth > 1 ? '0' : rentalOrderCountThisMonth == 0 ? '2':'1'} of 2 </span> orders remaining </span> 
              
            </p> 
            : 
            <p> 
              {checkForShipment(customerData.customer)?.isAllowOrder == true ?
                checkForShipment(customerData.customer)?.shipmentNumber == 1 ? <span> First order unlocked! Happy renting 💖 </span> :
                checkForShipment(customerData.customer)?.shipmentNumber == 2 ? <span> Second order unlocked! Happy renting 💖 </span> :
                checkForShipment(customerData.customer)?.shipmentNumber >= 3 ? <span> Your next order will unlock in {checkForShipment(customerData.customer)?.remainingPlanDays} {checkForShipment(customerData.customer)?.remainingPlanDays>1?'days':'day'} </span> : ''
                :
                <span> Your next order will unlock in {checkForShipment(customerData.customer)?.remainingPlanDays} {checkForShipment(customerData.customer)?.remainingPlanDays>1?'days':'day'} </span>
              }
            </p> 
          } 
      </div>

      <div className={`
          ${styles.order_box_width_container} 
          ${checkForTransferItem(lastOrderInfo, lastOrderInfo?.items, customerData.customer, systemSettings.systemSettings, lastOrderInfo?.orderid).totalNumberOFTransferdInNextOrder == 0 ? styles.mobile_all_box : ""}
          ${updateAvailableSlotsForOrder().totalBoxCount <= 7 && isLoading == false ? styles.order_box_width_one : "" }
          ${updateAvailableSlotsForOrder().totalBoxCount == 8 && isLoading == false ? styles.order_box_width_two : "" } 
          ${(updateAvailableSlotsForOrder().totalBoxCount > 8 && updateAvailableSlotsForOrder().totalBoxCount < 11) && isLoading == false ? styles.order_box_width_three : "" } 
          ${(updateAvailableSlotsForOrder().totalBoxCount > 10 && updateAvailableSlotsForOrder().totalBoxCount < 13) && isLoading == false ? styles.order_box_width_four : "" }
          ${updateAvailableSlotsForOrder().totalBoxCount > 12 && isLoading == false ? styles.order_box_width_five : "" } 
          ${isLoading == true && customerData.customer && (customerData.customer.subscription_name == "Wanderlust" || customerData.customer.subscription_name == "Fashionpass" ) ? styles.order_box_width_four : "" } 
          ${isLoading == true && customerData.customer && (customerData.customer.subscription_name == "Platinum" ) ? styles.order_box_width_two: "" } 
          ${isLoading == true && customerData.customer && (customerData.customer.subscription_name == "Gold" ) ? styles.order_box_width_two: "" } 
      `}
      >
       
        <p className={`${styles.my_order_link_text} d-none d-md-block`}> <span><Link href="/my-orders"> Go to My Orders page</Link></span> </p>
        

        {isLoading == false && customerData.customer != "not_login" && customerData.customer.tag_list?.indexOf('CurrentlyBorrowing') > -1 ? <>
          <MediaQuery minWidth={768} > 
            {renderCustomerLastOrderForPurchaseAndTransfer()}
          </MediaQuery>

          <MediaQuery maxWidth={767} > 
            <Swiper
              slidesPerView={lastOrderInfo && lastOrderInfo?.items?.length > 1 ? (screen ? (screen.width < 400 ? 1.8 : (screen.width > 400 && screen.width < 420) ?  1.81  :  1.82) : 1.6) : 1 }
              navigation={true}
              spaceBetween={ lastOrderInfo && lastOrderInfo?.items?.length > 1 ? 15 : 0}
              className={`${lastOrderInfo && lastOrderInfo?.items?.length > 1 ? 'order-carosel-mobile' : 'p-0'} plan-carosel-mobile plan-carosel-mobile-stylish-pick `}
              simulateTouch={false}
              >
              {renderCustomerLastOrderForPurchaseAndTransfer()}
            </Swiper>
          </MediaQuery>
          </> : "" }

        {isLoading == false && customerData.customer != "not_login" && customerData.customer.tag_list.indexOf('CurrentlyBorrowing') == -1 ?
          <>
            <MediaQuery minWidth={768} > 
              {renderCustomerCurrentlyOrderingInfo()}
            </MediaQuery>
            <MediaQuery maxWidth={767} > 
              {
                // (checkForTransferItem(lastOrderInfo, lastOrderInfo?.items, customerData.customer, systemSettings.systemSettings, lastOrderInfo?.orderid).totalNumberOFTransferdInNextOrder == 0 && (lastOrderInfo?.order_tags && lastOrderInfo?.order_tags.indexOf('return_inspected') == -1)) ?
              checkForTransferItem(lastOrderInfo, lastOrderInfo?.items, customerData.customer, systemSettings.systemSettings, lastOrderInfo?.orderid).totalNumberOFTransferdInNextOrder == 0 ?
              <div className={styles.empty_container}>
                { updateAvailableSlotsForOrder().remainingSlots.length > 0 && updateAvailableSlotsForOrder().remainingSlots.map((val, ind) => {
                  return (
                    <div key={'slot'+ind} className={`${styles.empty_item_box}`}> 
                      <div className={styles.inner_item}>
                      {(val.type == 1 || val.type == 2) ? <p >{val.type == 1 ? <span>Clothing <br/> Item</span> : 'Complimentary Accessory' }</p> : <p> Clothing item <br/> OR <br/> 2 Accessories</p> }
                        </div>
                    </div>
                  )
                })
              }
              </div>  
              : 
              <Swiper
                slidesPerView={screen ? (screen.width < 400 ? 1.8 : (screen.width > 400 && screen.width < 420) ?  1.81  :  1.82) : 1.6 }
                navigation={true}
                spaceBetween={15}
                className={`order-carosel-mobile plan-carosel-mobile plan-carosel-mobile-stylish-pick`}
                id="stylishpick_swiper"
                simulateTouch={false}
                >
                {renderCustomerCurrentlyOrderingInfo()}
              </Swiper>
              }
          </MediaQuery>

          </> 
        : "" }
      </div>

      {isLoading == true ? 
        <>
          <MediaQuery minWidth={768} > 
            {loadingCurrentlyOrderingInfo()}
          </MediaQuery>
          {isServerSide == false ? 
          <MediaQuery maxWidth={767} > 
            <Swiper
              slidesPerView={screen ? (screen.width < 400 ? 1.8 : (screen.width > 400 && screen.width < 420) ?  1.81  :  1.82) : 1.6 }
              navigation={true}
              spaceBetween={15}
              className={`order-carosel-mobile plan-carosel-mobile plan-carosel-mobile-stylish-pick`}
              id="stylishpick_swiper"
              simulateTouch={false}
              >
              {loadingCurrentlyOrderingInfo()}
            </Swiper>
          </MediaQuery> : "" }
        </> : "" }

      <p className={`${styles.my_order_link_text} d-block d-md-none`}> <span><Link href="/my-orders"> Go to My Orders page</Link></span> </p>

      {checkForShipment(customerData.customer)?.shipmentNumber == 3  &&  checkForShipment(customerData.customer)?.isAllowOrder ?
        <div className={styles.unlock_shipment}>
          <MediaQuery minWidth={768} >
            <img className={styles.more_heading} src={Gconfig.fashionpassImageStorage+"assets/img/want-more-pink.svg"}/>   
          </MediaQuery>
          <MediaQuery maxWidth={767} >
            <img className={styles.more_heading} src={Gconfig.fashionpassImageStorage+"assets/img/want-more.svg"}/>
          </MediaQuery>
          <p>Unlock a new order for $49.95</p>
          <span>Add to your cart to place your next order today</span>
        </div> : "" }
      {openOrderTransferedPopup  &&
        <TransferNextOrderPopup 
            isOpen={ openOrderTransferedPopup}
            toggle={()=>{  setOpenOrderTransferedPopup(false);setCancelTrasferPopup(false) }}
            item={transferItemInfo}
            defaultpaymentMethod={null}
            transferItemOrderId={lastOrderInfo.orderid}
            handlePageClick={()=>{}}
            paginationPage={null}
            customer={customerData}
            getLastRentalOrderInfo={getLastRentalOrderInfo}
            from="home"
            prevTransferInfoForNewTransferItem={prevTransferInfoForNewTransferItem}
            cancelTrasfer={cancelTrasferPopup}
        /> 
      }
    </div>
    </> 
  );
};

export default CurrentOrderHomeComp;