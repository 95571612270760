import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import styles from "styles/_transferPopup.module.scss"
import Gconfig from 'globalconfig';
import { capitializeString, transferItemFromOrder, getCustomerTags, cancelTransferRentOrder } from 'components/shared/globalFunctions';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/hooks/redux-hooks';
import { fetchCustomerSuccess } from '@/redux/actions/customerActions';

let closePopupTimeout = null

export default function transferNextOrderPopup(props) {
  
  const dispatch = useDispatch();
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [errMsg, setErrMsg] = useState('')
  const [isLoading, setIsLoading] = useState('')
  const [isItemSuccessfullyTransferred, setIsItemSuccessfullyTransferred] = useState(false)
  const customerData = useAppSelector((state) => state.customer);

  let item = props.item
  let paymentMethod = props.defaultpaymentMethod

   async function itemTransferToNext() {
    
        setErrMsg('')
        if(!check2 || !check3) {
            setErrMsg('please agree to the terms before proceeding.')
            return
        }

        let obj = {
            "customer_id": props.customer?.customer?.customer_id,
            "order_id": props.transferItemOrderId,
            "order_item_id": item.id,
            "order_type" :"transferitem",
            "from_ZenDesk": "false",
            "isChargeOnTransfer": false
        }
        setIsLoading(true)
    
        let res = await transferItemFromOrder(obj)
        if(res.success) {
            setIsLoading(false)
            setIsItemSuccessfullyTransferred(true)
            props.handlePageClick(props.paginationPage, 'transfer_popup')
            dispatch(getCustomerTags(props.customer?.customer?.customer_id))
            if(props.from == "home") {
                props.getLastRentalOrderInfo()
            }
            closePopupTimeout = setTimeout(()=> {
                props.toggle()
            },3000)
        }
        else {
            setIsLoading(false)
            setErrMsg(res.message)
        }  
   }

   
   async function itemCancelTransfer() {
    
    setErrMsg('')
    if(!check2 || !check3) {
        setErrMsg('please agree to the terms before proceeding.')
        return
    }

    let obj = {
        "customer_id": props.customer?.customer?.customer_id,
        "order_id": props.transferItemOrderId,
        "order_item_id": item.id,
    }
    setIsLoading(true)

    let res = await cancelTransferRentOrder(obj)
    if(res.success) {
        setIsLoading(false)
        setIsItemSuccessfullyTransferred(true)
        props.handlePageClick(props.paginationPage, 'transfer_popup')
        dispatch(getCustomerTags(props.customer?.customer?.customer_id))
        if(props.from == "cart"){
            let products = props.lastOrderTransferItem
            let remainingItems = products.filter(function (prod) { return prod.id != item.id});
            props.updateLastOrderTransferItems(remainingItems, props._this)

        }else if(props.from == "home"){
            props.getLastRentalOrderInfo()
        }
        let lastOrderItem = customerData.customer?.lastRentalOrderItems
        let lastOrderItemArr = []
        let customer = customerData.customer
        if(lastOrderItem?.length > 0){
            lastOrderItem.map((val,ind)=>{
            if(val.id == item.id){
                val.tags = ""
                lastOrderItemArr.push(val)
            }else{
                lastOrderItemArr.push(val)
            }
            })
        }
        customer.lastRentalOrderItems = lastOrderItemArr
        dispatch(fetchCustomerSuccess(customer))
        closePopupTimeout = setTimeout(()=> {
            props.toggle()
        },3000)
    }
    else {
        setIsLoading(false)
        setErrMsg(res.message)
    }  
}
   


  return(
      <div>
            <Modal isOpen={props.isOpen} toggle={()=> {isLoading ? {} : clearTimeout(closePopupTimeout); props.toggle(); }} size='lg' centered className={`${styles.transfer_modal} basicModalLayout basicModalLayoutTransfer`} >
              {
                props.cancelTrasfer == true ? 
                <>
                <ModalHeader toggle={()=> {isLoading ? {} : clearTimeout(closePopupTimeout); props.toggle()}} className={styles.transfer_modal_header} >
                    <p className={`${styles.transfer_popupo_heading} `} > TRANSFER IN PROGRESS</p>  
                </ModalHeader> 
                <ModalBody className={styles.transfer_modal_body}>
                    <div className={styles.trans_image_and_content_container}>
                        <img className={styles.trans_prodimg} src={Gconfig.productImageUrl + item.product.product_thumbnail + '?profile=b2x3'} />
                        <div className={styles.trans_prod_info} >
                            <div className={styles.trans_prod_info_inner} >
                                <p className={`${styles.trans_prod_name} mt-0`}> { capitializeString(item.product.product_title) } </p>
                                <p className={styles.trans_prod_brand} > {item.sku.vendor} </p>
                            </div>

                            <p className={styles.trans_prodtext} > You decided to transfer this item but if you changed your mind you can cancel your transfer below! </p>

                            <p className={styles.trans_prodtext} > Heads up! 
                                <span className='' > Please make sure to send the transferred item back with your current order return if you do not wish to purchase it. By canceling this transfer we will be expecting it in your return package.</span>
                            </p>
                        </div>
                    </div>
                    
                    <label className={`${styles.transfer_check} ${styles.transfer_check_two}`}>
                        <input className={styles.transfer_check_inp} checked={check2} onChange={(e)=> { setCheck2(e.target.checked) } }  type="checkbox" name="check2" value=""/> 
                        I understand I will need to return this item with order #{(props.transferItemOrderId+1000)}
                    </label>
                    
                    <label className={styles.transfer_check}>
                        <input className={styles.transfer_check_inp} checked={check3} onChange={(e)=> { setCheck3(e.target.checked) } } type="checkbox" name="check3" value=""/> 
                        I agree to FashionPass's <Link legacyBehavior  href="/terms-of-service"><a target="_blank" className={`${styles.link_ts}`}>Terms of Service.</a></Link>
                    </label>
                    
                    <div className={styles.transfer_btn_container} >
                    {!isItemSuccessfullyTransferred ? <button className={styles.transfer_btn} disabled={isLoading} onClick={() => itemCancelTransfer() } > {isLoading ? <Spinner /> : "CANCEL TRANSFER"} </button> : ""}
                    {isItemSuccessfullyTransferred  ? <button className={`${styles.transfer_btn} ${styles.transfer_msg_successfull}`}> SUCCESSFULLY CANCELED </button> : ""}    
                    <p className={styles.transfer_errmsg} > {errMsg} </p>
                    </div>

                </ModalBody>
                </>
                :
                <>
                <ModalHeader toggle={()=> {isLoading ? {} : clearTimeout(closePopupTimeout); props.toggle()}} className={styles.transfer_modal_header} >
                    <p className={`${styles.transfer_popupo_heading} `} > TRANSFER TO MY NEXT ORDER </p>  
                </ModalHeader> 
                <ModalBody className={styles.transfer_modal_body}>
                    <div className={styles.trans_image_and_content_container}>
                        <img className={styles.trans_prodimg} src={Gconfig.productImageUrl + item.product.product_thumbnail + '?profile=b2x3'} />
                        <div className={styles.trans_prod_info} >
                            <div className={styles.trans_prod_info_inner} >
                                <p className={styles.trans_prod_name} > { capitializeString(item.product.product_title) } </p>
                                <p className={styles.trans_prod_brand} > {item.sku.vendor} </p>
                            </div>

                            <p className={styles.trans_prodtext} > Want to keep renting this item? Transfer to your next order for free!. This will count as one of your {props?.item?.product?.product_catagory == 1 ? "clothing" : "accessory"} items  in your next order </p>

                            <p className={styles.trans_prodtext} > Heads up! An item can only be transferred once.
                                <span className='d-inline d-md-block' > Please make sure to send the transferred item back with your next return if you do not wish to purchase it.</span>
                            </p>
                        </div>
                    </div>
                    
                    {paymentMethod ? <label className={styles.transfer_check}>
                    <div>  <input className={styles.transfer_check_inp} checked={check1} onChange={(e)=> { setCheck1(e.target.checked) } } type="checkbox" name="check1" value=""/> </div>
                        I understand that my default credit card ({paymentMethod.brand} ending in {paymentMethod.last4} - {paymentMethod.expiry.match(/.{1,2}/g)[0]}/20{paymentMethod.expiry.match(/.{1,2}/g)[1]} ) will immediately be charged $19.95 + tax to transfer this item.
                    </label> : "" }
                    
                    <label className={`${styles.transfer_check} ${styles.transfer_check_two}`}>
                        <input className={styles.transfer_check_inp} checked={check2} onChange={(e)=> { setCheck2(e.target.checked) } }  type="checkbox" name="check2" value=""/> 
                        I understand that I will keep this item and not return until my next order.
                    </label>
                    
                    <label className={styles.transfer_check}>
                        <input className={styles.transfer_check_inp} checked={check3} onChange={(e)=> { setCheck3(e.target.checked) } } type="checkbox" name="check3" value=""/> 
                        I agree to FashionPass's <Link legacyBehavior  href="/terms-of-service"><a target="_blank" className={`${styles.link_ts}`}>Terms of Service.</a></Link>
                    </label>
                    
                    <div className={styles.transfer_btn_container} >
                        {!isItemSuccessfullyTransferred ? <button className={styles.transfer_btn} disabled={isLoading} onClick={() => itemTransferToNext() } > {isLoading ? <Spinner /> : "TRANSFER TO NEXT ORDER"} </button> : "" }
                        {isItemSuccessfullyTransferred  ? <button className={`${styles.transfer_btn} ${styles.transfer_msg_successfull}`}> SUCCESSFULLY TRANSFERRED </button> : ""}
                        <p className={styles.transfer_errmsg} > {errMsg} </p>
                    </div>

                </ModalBody>
                </>
              }
            </Modal>
      </div>
  );
}