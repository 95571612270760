import React, { useEffect, useState } from 'react';
// import BaseLayout from '@/layouts/base-layout/base-layout';
import dynamic from 'next/dynamic';
const BaseLayout = dynamic(() => import('@/layouts/base-layout/base-layout'), { loading: () => <div></div> });
import HeroImage from '@/containers/home/hero-image/hero-image';
import HowItsWork from '@/containers/home/hiw/HowItsWork';
import RentOccasion from '@/containers/home/rent-occasion/rent-occasion';
import NewArrival from '@/containers/home/new-arrival/new-arrival';
import MissedCollection from '@/containers/home/missed-collection/missed-collection';
import Brands from '@/containers/home/brands/brands';
import MemberLoved from '@/containers/home/most-loved/most-loved';
import MemberQoutes from '@/containers/home/member-qoutes/member-qoutes';
import MemberMeetup from '@/containers/home/member-meetup/member-meetup';
import FpCommunity from '@/containers/home/fp-community/fp-community';
import styles from './home-index.module.scss';
import { useAppSelector } from '@/hooks/redux-hooks';
import PlansSection from '@/containers/plans/plans-section/plans-section';
import PlansCompareSection from '@/containers/plans/plans-compare-section/plans-compare-section'; 
import Faq from '@/containers/home/faq/faq';
import IndexHeaderImage from 'components/layouts/home/indexHeaderImage';
import Head from 'next/head';
import StylistSuggestions from '@/containers/home/stylist-suggestions/stylist-suggestions-index';
import CustomerOrderFeed from '@/containers/home/customer-order-feed/customer-order-feed-index';
import HolidayFavorites from '../holiday-favorites/holiday-favorites';
import Gconfig from 'globalconfig';
import jsCookie from 'js-cookie'
import BackInStock from '@/containers/back-stock/back-stock';
import HighestRatedCaroselIndex from '@/containers/highest-rated-carosel/highest-rated-carousel-index';
import FeaturedBrandsIndex from '../featured-brands-section/featured-brands-index';
import Link from 'next/link';
import { useResponsive } from '@/libraries/responsive';
import HomeBanner from '../home-banner/home-banner-index';
import Loader from 'components/shared/loadingComp';
import HoldingPopup from 'components/shared/holdingPopup';
import CurrentOrder from '@/containers/home/current-order/current-order-index'
import RentalClubSection from '@/containers/new-hiw/rental-club-section/rental_club_section';
import MemberReview from '@/containers/member-review-section/member-review';
// import BackInStock from '@containers/back-stock/back-stock.tsx';


interface IProps { 
  homepageLoad:boolean
 }

const HomeIndex: React.FC<IProps> = ({homepageLoad}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const renderingConfigData = useAppSelector((state) => state.renderingConfig);
  const customerData = useAppSelector((state) => state.customer);
  const systemSettings = useAppSelector((state) => state.systemSettings);
  // const [isShowRentalClubSection, setIsShowRentalClubSection] = useState(false);
  // const systemClearMobileCache = Gconfig.system_settings.clearCacheMobileApp
  const { isTablet } = useResponsive();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1025 && window.innerWidth > 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.removeItem('isProductPage')
    localStorage.removeItem('currentPage')
    localStorage.removeItem('currentPageBoard')
    // let abCookieValue = jsCookie.get('customerHomeAB');
    // if (abCookieValue != undefined) {
    //   if(abCookieValue.indexOf('B') > -1)
    //     setIsShowRentalClubSection(true)
    // }
    let fromApp = jsCookie.get('fromMobileApp')
    if (fromApp != undefined) {
      const clear_app_cache_setting = systemSettings.systemSettings.find(function (sett) { return (typeof sett != undefined && sett != undefined && sett.setting_id == Gconfig.system_settings.clearCacheMobileApp) });
      let settingValue = JSON.parse(clear_app_cache_setting?.setting_value)
      // if(jsCookie.get('clearAppCache') != settingValue.clearCache) {
      if (typeof window != undefined && window != undefined) {
        // console.log('systemClearMobileCache fire======')
        window.flutter_inappwebview.callHandler('clearCacheHandler', { 'success': true, 'clearCache': settingValue.clearCache })
        // jsCookie.set('clearAppCache',  settingValue.clearCache)
      }
      // }
    }

  }, [])

  return (
    <>
      {
        customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? '' :
          <Head>
            <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=YnRWbz"></script>
          </Head>
      }
      <BaseLayout>
      {
        <>
          {/* for sub user */}
          {renderingConfigData.show.indexHeaderImage && <IndexHeaderImage />}
          {/* for non sub user */}
          {renderingConfigData.show.indexHeader && <HeroImage />}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true &&  <CurrentOrder /> }
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true && <CustomerOrderFeed fromMyOrder={false} />}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true && renderingConfigData.show.indexRentOccasion && <RentOccasion />}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? <StylistSuggestions fromNew={false} isLogIn={true} /> : ""}
          {/* un-commited after testing */}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? <BackInStock /> : ''}

          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? <FeaturedBrandsIndex /> : ''}


          {/* un-commited after testing */}
          {/* {console.warn(renderingConfigData.show.indexNewArrival)} */}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true && <HolidayFavorites />}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true && <HighestRatedCaroselIndex />}

          {/* {renderingConfigData.show.plans &&
            <div className='home_plansec_container_mt' >
              <PlansSection isGrey={true} />
            </div>
          } */}
          {/* {renderingConfigData.show.plans && <PlansCompareSection />}  */}

          {(customerData.customer != 'not_login' && customerData.customer.has_subscription == true && renderingConfigData.show.indexHIW) ? <HowItsWork /> : ''}
          {customerData.customer != 'not_login' && customerData.customer.has_subscription == true ? '' : <MemberLoved />}
          {/* {(customerData.customer == 'not_login' || customerData.customer.has_subscription == false && renderingConfigData.show.indexRentOccasion) ? <RentOccasion isSmallScreen={isSmallScreen} /> : ""} */}
          {/* {renderingConfigData.show.indexNewArrival && <NewArrival />} */}
          {/* {(isShowRentalClubSection == true && (customerData.customer == 'not_login' || customerData.customer.has_subscription == false)) ? <RentalClubSection fromHomePage={true} />:""} */}
          {/* {customerData.customer != 'not_login' && customerData.customer.has_subscription ? renderingConfigData.show.indexMemberLoved && <MemberLoved /> : renderingConfigData.show.indexNewArrival && <NewArrival />} */}
          {renderingConfigData.show.indexBrands && <Brands isSmallScreen={isSmallScreen} />}
          {(customerData.customer == 'not_login' || customerData.customer.has_subscription == false )?<MemberReview />: ''}
          {(customerData.customer == 'not_login' || customerData.customer.has_subscription == false && renderingConfigData.show.indexRentOccasion) ? <RentOccasion isSmallScreen={isSmallScreen} /> : ""}

          
          {customerData.customer != 'not_login' &&  <MissedCollection/> }
          {/* {customerData.customer != 'not_login' && customerData.customer.has_subscription ? renderingConfigData.show.indexNewArrival && <NewArrival /> : renderingConfigData.show.indexMemberLoved && <MemberLoved />} */}
          {(customerData.customer != 'not_login' && customerData.customer.has_subscription == true && renderingConfigData.show.indexMemberQoutes) ? <MemberQoutes /> : ''}
          {/* {(customerData.customer != 'not_login' && customerData.customer.has_subscription == false) || (customerData.customer == 'not_login') ? <StylistSuggestions fromNew={false} isLogIn={false} /> : ""} */}
          {(customerData.customer != 'not_login' && customerData.customer.has_subscription == false) || (customerData.customer == 'not_login') ? <HomeBanner /> : ""}
          {/* {renderingConfigData.show.indexMemberMeetup && <MemberMeetup />} */}
          {/* {renderingConfigData.show.indexCommunityImage && <FpCommunity />} */}
          {/* {renderingConfigData.show.indexFAQs && <Faq />} */}
          <HoldingPopup isOpen={homepageLoad} />
        </>
      }
      </BaseLayout>
    </>
  );
};

export default HomeIndex;
