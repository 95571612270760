import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'styles/_fontsFamily.module.scss';
import styles from './back-stock.module.scss';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { addRemoveFavoriteItem } from 'components/shared/productFavFunctions';
import BoardsListTooltip from 'components/shared/boardsListTooltip';
import { getBackInStockSliderData } from 'components/shared/collectionAPIFunctions';
import { NextPage } from 'next';
import { API, ProductGrayImageId } from '@/constants/global-config';
import Link from 'next/link';
import 'moment-timezone';
import moment from 'moment';
import { getSystemSettingsById } from 'components/shared/globalFunctions';
import Login from 'components/shared/login';
import SignupPopup from 'components/shared/signupPopup';
import { new_backIn_stock_item_obj } from './back-stock-type'
import UpdatePrefrencePopup from 'components/shared/updatePrefrencesPopup';
import WaveLoader from 'components/waveLoader';
import ProductsCarouselsIndex from '@/containers/products-carousels/products-carousels-index';
import Gconfig from 'globalconfig';
interface IProps {
    newArrival?: new_backIn_stock_item_obj[];
}

const BackInStock: NextPage<IProps> = () => {
    const [signupModal, setSignupModal] = useState<boolean>(false);
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const [backInStockArr, setBackInStockArr] = useState<new_backIn_stock_item_obj[]>([]);
    const [boards, setBoards] = useState<[]>([]);
    const [isSubscription, setIsSubscription] = useState<boolean>(false);
    const [preferenceModal, setPreferenceModal] = useState<boolean>(false);
    const customer = useAppSelector((state) => state.customer);
    const customerBoard = useAppSelector((state) => state.customerBoards);
    const systemSettings = useAppSelector((state) => state.systemSettings);
    const [inCompleteProfile, setInCompleteProfile] = useState<boolean>(true)
    const [backInStockTitle, setBackInStockTitle] = useState<string>('');
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch();

    const jeansSize = customer.customer !== 'not_login' && (customer.customer.tag_list.filter((item: any) => item.includes('Jeans Size')))[0] !== undefined ? (customer.customer.tag_list.filter(item => item.includes('Jeans Size')))[0].replace("Jeans Size:", "") : ""
    const dressSize = customer.customer !== 'not_login' && customer.customer.tag_list.filter((item: any) => item.includes("Dress Size"))[0] !== undefined ? customer.customer.tag_list.filter(item => item.includes("Dress Size"))[0].replace("Dress Size:", "").split(':') : ""
    const topSize = customer.customer !== 'not_login' && customer.customer.tag_list.filter((item: any) => item.includes("Shirt & Top"))[0] !== undefined ? customer.customer.tag_list.filter(item => item.includes("Shirt & Top"))[0].replace("Shirt & Top:", "") : ""

    const sizeObj = {
        dressSize: dressSize[1],
        dressNum: dressSize[0],
        topSize,
        jeansSize
    }

    const handleFetchBackInStockData = async () => {
        const sizeArr = [sizeObj.dressSize, sizeObj.dressNum, sizeObj.topSize, sizeObj.jeansSize].join()
        let isLogin = false;
        let isAutoHide = 'true';
        if (customer.customer != 'not_login') {
            isLogin = true;
            if(customer.customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide='false'
        }
        setLoading(true)
        const tags = await handlingTags()
        const response = await getBackInStockSliderData('clothing', isLogin, sizeArr, tags, isAutoHide)
        if (response) {
            setLoading(false)
            const newBackInStockData = response && response.success ? response.product_list.result_items : [];
            if (newBackInStockData && newBackInStockData.length > 0) {
                // let removeOnlyPurchaseItemArr = newBackInStockData.filter((item: any, index: any) => {
                //     return checkPurchaseOnly(item) == false
                // })
                setBackInStockArr(newBackInStockData);
            }
        } else {
            setLoading(false)
        }

    }

    const newUpdateFunc = async ({ success, response, profileValue, customer }: any) => {
        setLoading(true)
        setPreferenceModal(false)
        let isAutoHide = 'true';
        if(customer != 'not_login' && customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide='false'
        if (success) {
            const updateDressSizeArr = (profileValue.dress_size).split('-')
            const updateJeansSize = profileValue.jeans_size
            const updateTopSize = profileValue.top_size
            const updateDressSize = updateDressSizeArr[0]
            const updateDressSizeNum = updateDressSizeArr[1]

            const sizeArr = [updateJeansSize, updateDressSize, updateDressSizeNum, updateTopSize].join()
            const tags = await handlingTags()
            const response = await getBackInStockSliderData('clothing', true, sizeArr, tags, isAutoHide)
            if (response.success) {
                setLoading(false)
                setBackInStockArr(response.product_list.result_items)
                setPreferenceModal(false)
            } else {
                setLoading(false)
                setPreferenceModal(false)
            }

        } else {
            setLoading(false)
            setPreferenceModal(false)
        }

    }

    useEffect(() => {
        handleFetchBackInStockData();

        const element = document.getElementsByTagName("body")[0];
        element.addEventListener("touchmove", (e: any) => {
            try {
                if (e.target.className && e.target.className.indexOf('newBoard-tooltip') == -1 && e.target.className.indexOf('fa-heart') == -1 && e.target.className.indexOf('boardItem') == -1) {
                    let boardsList = document.querySelectorAll('.newBoard-tooltip')
                    boardsList.forEach((val, ind) => {
                        if (boardsList[ind]) {
                            boardsList[ind].style.display = "none"
                        }
                    })
                }
            } catch (error) {
                console.log(error);
            }
        });
    }, []);

    useEffect(() => {
        if (customer.customer != 'not_login' && customer.customer.has_subscription) {
            setIsSubscription(true);
        } else {
            setIsSubscription(false);
        }

        let boards: any = customerBoard.customerBoards;
        if (boards) {
            setBoards(boards);
        }
    }, [customer.customer, customerBoard.customerBoards]);

    const handlingTags = async () => {
        let tags = ''
        if (systemSettings.systemSettings.length > 0) {
            systemSettings.systemSettings.filter((val) => {
              if (val.setting_id == Gconfig.system_settings.backInStockSliderTagId) {
                let value = JSON?.parse(val.setting_value);
                setBackInStockTitle(value.backInStockCarouselTitle)
                tags = value?.tags
              }
            });
        }
        if(tags == ''){
            let systemsResponse = await getSystemSettingsById(Gconfig.system_settings.backInStockSliderTagId);
            if (systemsResponse && systemsResponse.success) {
              let setting_value = JSON?.parse(systemsResponse.system_settings.system_settings[0]?.setting_value)
              if (setting_value.backInStockCarouselTitle != "") {
                setBackInStockTitle(setting_value.backInStockCarouselTitle)
                tags = setting_value.tags
              }
            }
        }
        return tags
      }

    return (
        <>
            {loading ? <div className={`${styles.container} ${styles.main_loading}`}><WaveLoader /></div> :
                backInStockArr.length > 5 ?
                    <>
                        <div id='blockInStock' className={`${styles.container} home-new-arrival ${isSubscription ? styles.custom_m_container : ''}`}>
                            <div className={`${styles.title_div} ${isSubscription ? styles.custom_mt : ''}`}>
                                <h2 className={styles.title}>
                                    {backInStockTitle != '' ? backInStockTitle : 'Back In Stock'}
                                </h2>
                            </div>
                            <p className={`${styles.product_filters_applied}`}><span onClick={() => setPreferenceModal(true)}>See what filters we applied</span></p>

                            <ProductsCarouselsIndex sliderName={'backinstock'} sliderData={backInStockArr} customerBoards={boards} isLoopAllowed={true} onSwiperSlideChange={()=>{}} currentSwiperSlide={0} swiperSliderName="back-in-stock" />
                            <p className={`${styles.product_filters_applied_mobile}`}><span onClick={() => setPreferenceModal(true)}>See what filters we applied</span></p>

                            { preferenceModal ? <UpdatePrefrencePopup isOpen={preferenceModal} toggle={(val: boolean) => setPreferenceModal(val)} callBackFunc={newUpdateFunc} setInCompleteProfile={setInCompleteProfile} btn_name='See Back In Stock' /> : ''}

                        </div>
                    </>
                    : ''
            }
        </>
    );
};

export default BackInStock;

