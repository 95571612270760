import React from 'react';
import styles from './member-review.module.scss';
import Gconfig from 'globalconfig';
import { useState } from 'react';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false });
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Link from 'next/link';
import Rating from '@mui/material/Rating';
import Router from 'next/router';
import { pink } from '@mui/material/colors';




export const MemberReview = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [memberReviews, setMemberReviews] = useState([
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Sydney_J_review_image.png',
            heading_one: 'Sydney J.',
            sub_heading: '“FP is doing everything right”',
            description: 'I had tried another rental service where the clothes came in bad condition, but everything I’ve rented from FashionPass feels brand new. They also have tons of member reviews which helps so much when you’re ordering online.',
        },
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Sami_S_review_image.png',
            heading_one: 'Sami S.',
            sub_heading: '“One of my best <br /> money saving tips!”',
            description: 'Since joining FP, I have been able to try out so many different styles & brands without having to commit to purchasing them! It has allowed me to continue to develop and discover my personal style.',
        },
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Sarah_M_review_image.png',
            heading_one: 'Sarah M.',
            sub_heading: '“The ultimate bridal hack!”',
            description: 'I rented all my bridal outfits from the bachelorette to the honeymoon! Not only did I have the perfect outfit for every occasion, I saved thousands of dollars for my wedding from renting!',
        },
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Nikita_K_review_image.png',
            heading_one: 'Nikita K.',
            sub_heading: '“Where’d you get your outfit?!”',
            description: 'I get SO many compliments when I wear FashionPass because the outfits are that good! I no longer have to fight the urge to buy new clothes every weekend and then regret spending money on an outfit I’d only wear once.',
        },
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Jennifer_M_review_image.png',
            heading_one: 'Jennifer M.',
            sub_heading: '“A last minute lifesaver!”',
            description: 'I was cutting it so close with a wedding/trip to Mexico and my order came literally next day! It made packing and planning my outfits so much easier. I have to say, the best part was no laundry when I got home!',
        },
        {
            img_one: Gconfig.fashionpassImageStorage + 'assets/img/Sarah_P_review_image.png',
            heading_one: 'Sarah P.',
            sub_heading: '“The best form of self care”',
            description: 'As a mom, it’s hard to prioritize yourself and FP has been such an amazing treat + confidence booster. At a time where your body is going through so many changes, it was so helpful for me to feel like myself again.',
        }
    ])

    return (
        <div className={`${styles.member_container} home_member_review`}>
            <h1 className={styles.member_heading}>
                Join 100,000<span className={styles.plus_sign}>+</span> Happy Members
            </h1>
            <div className={styles.img_container}>
                <span className={styles.rating_number}>5.0</span>
                <Rating className={styles.rating_stars} name="size-large" defaultValue={5} readOnly  />
            </div>
            <p className={styles.member_para}>Based on 600+ Google Reviews</p>
            <MediaQuery minWidth={768}>  
                <div className={`mt-4 ${styles.main}`}>
                    <div className={`row mr-0 ml-0 ${styles.sub_container}`}>
                        {memberReviews.map((item) => {
                            return (
                                <div className={styles.box}>
                                    <div className={styles.container_one}>
                                        <div className={styles.img_one}>
                                            <img src={item.img_one} />
                                        </div>
                                        <div className={styles.text_one}>
                                            <h1 className={styles.heading_one}>{item.heading_one}</h1>
                                            <Rating className={styles.rating_stars_black} name="size-large" defaultValue={5} readOnly  />
                                            <h2 dangerouslySetInnerHTML={{ __html: item.sub_heading }} className={styles.sub_heading}></h2>
                                            <p className={styles.description}>{item.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                 <button onClick={()=> Router.push('/signup')} className={styles.profile_btn}>Create a Profile</button>
            </MediaQuery>

            <MediaQuery maxWidth={767}>
                <div className={styles.swiper_container}>
                    <Swiper
                    spaceBetween={10}
                    slidesPerView={1.05}
                    onSlideChange={(swiper) => {setActiveIndex(swiper.activeIndex);}}
                    onSwiper={(swiper) => {} }
                    scrollbar={{ draggable: true }}
                    pagination={true}
                    modules={[Pagination]}
                    className={styles.custome_slider}>
                        {memberReviews.map((item) => {
                            return (
                                <SwiperSlide>
                                    <div className={styles.container_one}>
                                        <div className={styles.img_one}>
                                            <img src={item.img_one} />
                                        </div>
                                        <div className={styles.text_one}>
                                            <h1 className={styles.heading_one}>{item.heading_one}</h1>
                                            <Rating className={styles.rating_stars_black} name="size-large" defaultValue={5} readOnly  />

                                            <h2 dangerouslySetInnerHTML={{ __html: item.sub_heading }} className={styles.sub_heading}></h2>

                                            <p className={`${styles.description} ${styles.description_one}`}>{item.description}</p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
                <button onClick={()=> Router.push('/signup')} className={styles.profile_btn}>Create a Profile</button>

              
            </MediaQuery>

        </div>
    )
}


            export default MemberReview
