import React, { useEffect, useState } from 'react'
import { NextPage } from 'next';
import WaveLoader from 'components/waveLoader';
import Link from 'next/link';
import { getProductsByHandle } from '@/services/collections';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import styles from './highest-rated-carousel.module.scss';
import Gconfig from 'globalconfig';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { getSystemSettingsById } from 'components/shared/globalFunctions';
import UpdatePrefrencePopup from 'components/shared/updatePrefrencesPopup';
import ProductsCarouselsIndex from '@/containers/products-carousels/products-carousels-index';


interface IProps { }

const HighestRatedCaroselIndex: NextPage<IProps> = () => {

    const [productData, setProductData] = useState([]);
    const [boards, setBoards] = useState<[]>([]);
    const customerBoard = useAppSelector((state) => state.customerBoards);
    const systemSettings = useAppSelector((state) => state.systemSettings);
    const [isSubscription, setIsSubscription] = useState<boolean>(false);
    const [preferenceModal, setPreferenceModal] = useState<boolean>(false);
    const [inCompleteProfile, setInCompleteProfile] = useState<boolean>(true);
    const [highRatedTitle, setHighRatedTitle] = useState<string>('');
    const customer = useAppSelector((state) => state.customer);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)
    const [isMoveToUpdateProfile, setIsMoveToUpdateProfile] = useState(false)
    


    useEffect(() => {

        let  dressSize:any = '', top_size = '', jeans_size = '', ds = ['', ''];
        if(customer.customer != "not_login") {

        customer.customer.tag_list.map((tag:any) => {
            if(tag.indexOf('Dress Size:') > -1) {
                dressSize = tag.split(':')
                if(dressSize && dressSize.length > 0) {
                ds [1] = dressSize[1] ? dressSize[1] : ''
                ds [0] = dressSize[2] ? dressSize[2] : ''
                }
            }

            if(tag.indexOf('Jeans Size:') > -1) {
                jeans_size = tag.split(':')[1]
            }

            if(tag.indexOf('Shirt & Top:') > -1) {
                top_size = tag.split(':')[1]
            }
        })

            if(top_size == '' ||  ds[0] == '' || ds[1] == '' || jeans_size == '') {
                setIsMoveToUpdateProfile(true)
            }
            else {
                setIsMoveToUpdateProfile(false)
            }
            
            getData();
        }

    }, [])

    useEffect(() => {
        if (customer.customer != 'not_login' && customer.customer.has_subscription) {
            setIsSubscription(true);
        } else {
            setIsSubscription(false);
        }

        let boards: any = customerBoard.customerBoards;
        if (boards) {
            setBoards(boards);
        }
    }, [customer.customer, customerBoard.customerBoards]);

    const handlingTags = async () => {
        let systemsResponse = await getSystemSettingsById(Gconfig.system_settings.highRatedSliderTagId);
        if (systemsResponse && systemsResponse.success) {
          let setting_value = JSON?.parse(systemsResponse.system_settings.system_settings[0]?.setting_value)
          if (setting_value.highRatedCarouselTitle != "") {
            setHighRatedTitle(setting_value.highRatedCarouselTitle)
            return setting_value.tags
          }
        }
      }

    const getData = async () => {
        setLoading(true)
        setPreferenceModal(false)
        try {
            let customer_token = getAuthTokenfromcookie(false);
            let noCache = undefined;
            let isAutoHide = 'true';

            let sizeArr:any = null
            let  dressSize:any = '', top_size = '', jeans_size = '', ds = ['', ''];
            if(customer.customer != "not_login") {
    
            customer.customer.tag_list.map((tag:any) => {
                if(tag.indexOf('Dress Size:') > -1) {
                    dressSize = tag.split(':')
                    if(dressSize && dressSize.length > 0) {
                    ds [1] = dressSize[1] ? dressSize[1] : ''
                    ds [0] = dressSize[2] ? dressSize[2] : ''
                    }
                }
    
                if(tag.indexOf('Jeans Size:') > -1) {
                    jeans_size = tag.split(':')[1]
                }
    
                if(tag.indexOf('Shirt & Top:') > -1) {
                    top_size = tag.split(':')[1]
                }
            })
    
            if(top_size != '' &&  ds[0] != '' && ds[1] != '' && jeans_size != '') {
                 sizeArr = [ds[1], ds[0], top_size, jeans_size].join()
            }
        }

            const tags = await handlingTags()
            let query = ''
            if(customer.customer != 'not_login' && customer.customer.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide = 'false'
            if (tags != "" && tags != undefined) {
                query = Gconfig.collectionAPI + `collections/SearchByHandle/highest-rated?filter_tags_any_list=|${tags}&filter_vendor_ids=&filter_sizes=${sizeArr != null ? sizeArr : ""}&items_per_page=48&excludes=stats,categorylist,bump-photo&sort_by=rev:4.5&sort_order=desc&page=1&show_hidden_items=3&in_stock=1&in_stock_sizes=1&isprice_for_customer=true&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
            }else{
                query = Gconfig.collectionAPI + `collections/SearchByHandle/highest-rated?filter_tags_any_list=&filter_vendor_ids=&filter_sizes=${sizeArr != null ? sizeArr : ""}&items_per_page=48&excludes=stats,categorylist,bump-photo&sort_by=rev:4.5&sort_order=desc&page=1&show_hidden_items=3&in_stock=1&in_stock_sizes=1&isprice_for_customer=true&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
            }
            const data = await getProductsByHandle(query.replace('ddfdfdfdf,', ''), noCache, { token: customer_token, guid: true });
            if (data?.success) {
                setProductData(data?.product_list?.result_items);
                setLoading(false)
                setPreferenceModal(false)
            }
        } catch (error) {
            console.error('An error occurred:', error);
            setLoading(false)
            setPreferenceModal(false)
        }
    };


    return (
        <>
            {loading ? <div className={`${styles.container} ${styles.main_loading}`}><WaveLoader /></div> :
                productData.length > 5 ?
                <div id='highestRated' className={`${styles.container} home-new-arrival ${isSubscription ? styles.custom_m_container : ''}`}>
                    <div className={`${styles.title_div} ${isSubscription ? styles.custom_mt : ''}`}>
                        <h2 className={styles.title}>{highRatedTitle != '' && highRatedTitle != undefined ? highRatedTitle : 'Highest Rated'}</h2>
                        <p className='d-block d-md-none'> <Link legacyBehavior href="/highest-rated"  className={styles.link_mob_hrated}>  View All  </Link> </p>
                    </div>

                    <div>
                        {!isMoveToUpdateProfile ? <p className={`${styles.product_filters_applied}`}><span onClick={() => setPreferenceModal(true)}>See what filters we applied</span></p> :
                        <p className={`${styles.product_filters_applied}`}><span><Link href='/my-account?tab=1'>See what filters we applied</Link></span></p>}
                    </div>
                  
                    <ProductsCarouselsIndex sliderName={'highrated'} sliderData={productData} customerBoards={boards} isLoopAllowed={true} onSwiperSlideChange={()=>{}} currentSwiperSlide={0} swiperSliderName="highest-rated" />
         
                    {!isMoveToUpdateProfile ? <p className={`${styles.product_filters_applied_mobile}`}><span onClick={() => setPreferenceModal(true)}>See what filters we applied</span></p> :
                    <p className={`${styles.product_filters_applied_mobile}`}><span><Link href='/my-account?tab=1'>See what filters we applied</Link></span></p>}

                    { preferenceModal ? <UpdatePrefrencePopup isOpen={preferenceModal} toggle={(val: boolean) => setPreferenceModal(val)} callBackFunc={getData} setInCompleteProfile={setInCompleteProfile} btn_name='See Highest Rated' /> : ''}<br/>
                </div> : ''
            }
        </>

    )
}

export default HighestRatedCaroselIndex

