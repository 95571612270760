import Gconfig from 'globalconfig'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import styles from './home-banner-index.module.scss'
import { useSelector } from 'react-redux';


type Props = {}

const HomeBanner = (props: Props) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const customer = useSelector(state => state.customer.customer)
    const [tableMode, setTabletMode] = useState(false);

    let lastRentalOrderId = (customer != 'not_login' && customer.lastRentalOrder && customer.lastRentalOrder.orderid) ? customer.lastRentalOrder.orderid : -1 ;

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 1200);
          setTabletMode(window.innerWidth < 768)
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      
    return (
        <div className={styles.container}>
            {tableMode ?
                <>
                   <div className={styles.container}>
                   <img src={Gconfig.fashionpassImageStorage + 'assets/img/footer_banner_sept_mob.jpg?profile=a'} alt="img" width='100%' />
                        <div className={styles.haeding_box}>
                            <p className={styles.heading}>Confidence, delivered.</p>
                            {lastRentalOrderId == -1 ? <Link href='/signup'><p className={styles.try_now_btn}>Join the club</p></Link> : ''}
                            {lastRentalOrderId > -1 ? <Link href='/signup'><p className={styles.try_now_btn}>REACTIVATE</p></Link> : ''}
                        </div>
                    </div>
                </>
                :
                <>
                    <div className={styles.container}>
                        <img src={Gconfig.fashionpassImageStorage + `assets/img/footer_banner_sept_desk.jpg?profile=a`} alt="img" width='100%' />
                        <div className={styles.haeding_box}>
                            <p className={isSmallScreen ? `${styles.heading} ${styles.small_text}` : styles.heading}>Confidence, delivered.</p>
                            {lastRentalOrderId == -1 ? <Link href='/signup'><p className={isSmallScreen ? `${styles.try_now_btn} ${styles.try_not_button_small}` : styles.try_now_btn}>Join the club</p></Link> : ''}
                            {lastRentalOrderId > -1 ? <Link href='/signup'><p className={isSmallScreen ? `${styles.try_now_btn} ${styles.try_not_button_small}` : styles.try_now_btn}>REACTIVATE</p></Link> : ''}
                        </div>
                    </div>
                    
                </>}
        </div>
    )
}
export default HomeBanner