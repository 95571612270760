import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper';
import 'styles/_fontsFamily.module.scss';
import styles from './missed-collection.module.scss';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { addRemoveFavoriteItem } from 'components/shared/productFavFunctions';
import BoardsListTooltip from 'components/shared/boardsListTooltip';
import { getHomePageSliderData } from 'components/shared/collectionAPIFunctions';
import { NextPage } from 'next';
import { API, ProductGrayImageId } from '@/constants/global-config';
import Link from 'next/link';
import 'moment-timezone';
import moment from 'moment';
import { checkPurchaseOnly, isUsedProdPriceChangeForNonSub, saveViewProductActivityCarosel } from 'components/shared/globalFunctions';
import Login from 'components/shared/login';
import SignupPopup from 'components/shared/signupPopup';
import ProductsCarouselsIndex from '@/containers/products-carousels/products-carousels-index';

var alreadyViewProductArr = []

type missed_item_obj = {
  id: number;
  title: string;
  handle: string;
  prduct_cost: number;
  retail: number;
  discount: number;
  sale_price: number;
  newitem_discount: number;
  useitem_discount: number;
  vendor: string;
  vendor_handle: string;
  pos: number;
  pos_in_result: number;
  created: number;
  published_at: number;
  shopify_id: number;
  newArrival: string;
};

interface IProps {
    missedItem?: missed_item_obj[];
}

var alreadyViewProductArr = []
const MissedItem: NextPage<IProps> = ({ missedItem }) => {
  // const [isLogin, setIsLogin] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [missedItemArr, setMissedItemArr] = useState<missed_item_obj[]>([]);
  const [boards, setBoards] = useState<[]>([]);
  const [isSubscription, setIsSubscription] = useState(false);
  const customer = useAppSelector((state) => state.customer);
  const customerBoard = useAppSelector((state) => state.customerBoards);
  const systemSettings = useAppSelector((state) => state.systemSettings);
  const dispatch = useAppDispatch();

  const productRefs = useRef(new Map());


  const handleFetchMissedItemData = async () => {
    let isLogin = false;
    let isAutoHide = 'true';
    if (customer.customer != 'not_login') {
      isLogin = true;
      if(customer.customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide='false'
    }
    const response = await getHomePageSliderData('what-you-missed', isLogin, 1, '',isAutoHide );  //new-arrivals
    const missedItemData = response && response.success ? response.product_list.result_items : [];

    if (missedItemData && missedItemData.length > 0) {
      let removeOnlyPurchaseItemArr = missedItemData.filter((item: any, index: any) => {
        return checkPurchaseOnly(item) == false
      })
      setMissedItemArr(removeOnlyPurchaseItemArr);

      // after removing only sale item is remainiong items is less than 48 then again api hit and get more items and merge in it
      if (removeOnlyPurchaseItemArr && removeOnlyPurchaseItemArr.length < 48) {
        const res = await getHomePageSliderData('what-you-missed', isLogin, 2, '', isAutoHide);
        const moreMissedItemData = res && res.success ? res.product_list.result_items : [];
        if (moreMissedItemData && moreMissedItemData.length > 0) {
          let moreRemoveOnlyPurchaseItemArr = moreMissedItemData.filter((item: any, index: any) => {
            return checkPurchaseOnly(item) == false
          })
          removeOnlyPurchaseItemArr = removeOnlyPurchaseItemArr.concat(moreRemoveOnlyPurchaseItemArr)
          setMissedItemArr(removeOnlyPurchaseItemArr);
        }
      }
    }
  }

  useEffect(() => {
    if (missedItem === undefined || missedItem === null) {
      handleFetchMissedItemData();
    } else {
      setMissedItemArr(missedItem);
    }
    const element = document.getElementsByTagName("body")[0];
    element.addEventListener("touchmove", (e: any) => {
      try {
        if (e.target.className && e.target.className.indexOf('newBoard-tooltip') == -1 && e.target.className.indexOf('fa-heart') == -1 && e.target.className.indexOf('boardItem') == -1) {
          let boardsList = document.querySelectorAll('.newBoard-tooltip')
          boardsList.forEach((val, ind) => {
            if (boardsList[ind]) {
              boardsList[ind].style.display = "none"
            }
          })
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  useEffect(() => {
    // if (customer.customer != 'not_login') {
    //   setIsLogin(true);
    // } else {
    //   setIsLogin(false);
    // }

    if (customer.customer != 'not_login' && customer.customer.has_subscription) {
      setIsSubscription(true);
    } else {
      setIsSubscription(false);
    }

    let boards: any = customerBoard.customerBoards;
    if (boards) {
      setBoards(boards);
    }
  }, [customer.customer, customerBoard.customerBoards]);

  const toggleFavorite = (e: any, id: any, isLogin: any) => {
    if (isLogin != 'not_login') {
      let selector = e.target.classList;
      if (selector.toString().indexOf('far') > -1) {
        // if (e.target.parentElement) e.target.parentElement.classList.add('filled');
        selector.remove('far');
        selector.add('fas');
        dispatch(addRemoveFavoriteItem(parseInt(id), 1, true));
        // props._addRemoveFavoriteItem(parseInt(id), 1, true);
      } else if (screen.width > 767) {
        selector.remove('fas');
        selector.add('far');
        if (e.target.parentElement) e.target.parentElement.classList.remove('filled');
        // props._addRemoveFavoriteItem(parseInt(id), 0, true);
        dispatch(addRemoveFavoriteItem(parseInt(id), 0, true));
        var selectedBoards = [].slice.call(e.target.parentElement.children[1].children[0].children);
        selectedBoards.filter((boards: any) => {
          if (boards.classList) {
            boards.classList.remove('selected');
          }
        });
      }
      if (screen.width <= 767) {
        let boardsList = document.querySelectorAll('.newBoard-tooltip')
        boardsList.forEach((val, ind) => {
          if (boardsList[ind]) {
            boardsList[ind].style.display = "none"
          }
        })
        let boardId = 'board_' + id
        if (document.getElementById(boardId)) {
          document.getElementById(boardId).style.display = 'block'
          if (document.querySelector('.backdrop-boardTooltip'))
            document.querySelector('.backdrop-boardTooltip').style.display = 'block'
        }
      }
    } else {
      setSignupModal(!signupModal);
    }
  };

  function toggleLoginModal() {
    setOpenLoginModal(true);
    setSignupModal(false);
    setTimeout(() => {
      setOpenLoginModal(false);
    }, 500);
  }

  return (
    missedItemArr?.length > 4 ? <div className={`${styles.container} home-new-arrival ${isSubscription ? styles.custom_m_container : styles.custom_m_container_nonsubs}`}>
      { missedItemArr?.length > 0 ? <div className={`${styles.title_div} ${isSubscription ? styles.custom_mt : styles.custom_mt_nonsub}`}>
        <div className={styles.newDiv}></div>
        <h2 className={styles.title}>
          {/* Missed Collection */}
          <Link href="/what-you-missed" >{'What You Missed'}</Link>{' '}
        </h2>
        <div className={styles.view_all_link}>
          <Link href="/what-you-missed" > View All </Link>
          {/* <span></span> */}
        </div>
      </div> : "" }

      <ProductsCarouselsIndex sliderName={'backinstock'} sliderData={missedItemArr} customerBoards={boards} isLoopAllowed={true} onSwiperSlideChange={()=>{}} currentSwiperSlide={0} swiperSliderName="what-you-missed" />
      <br/>
      <SignupPopup isOpen={signupModal} toggle={() => toggleFavorite(false, 0, customer.customer)} openLogin={toggleLoginModal} />
      <Login isSignup={true} isOpen={openLoginModal} />
    </div> : <></>
  );
};

export default MissedItem;

