import React, { useEffect, useState } from 'react';
import styles from './featured-brands-index.module.scss';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false },);
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import Link from 'next/link';
import Gconfig from 'globalconfig';
import { getSystemSettingsById } from 'components/shared/globalFunctions';

interface IProps { }

const FeaturedBrandsIndex: React.FC<IProps> = () => {
    const [featuredDataDesktop, setFeaturedDataDesktop] = useState([]);
    const [featuredDataMobile, setFeaturedDataMobile] = useState([]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        let systemsResponse = await getSystemSettingsById(Gconfig.system_settings.featuredBrandSettingId);
        if (systemsResponse.success) {
            let systemSettingDesktop = JSON.parse(systemsResponse.system_settings.system_settings[0].setting_value).desktop;
            setFeaturedDataDesktop(systemSettingDesktop)
            let systemSettingMobile = JSON.parse(systemsResponse.system_settings.system_settings[0].setting_value).mobile;
            setFeaturedDataMobile(systemSettingMobile);
        }
    }

    const imagesStyleDiv = (imageUrl: string, title: string, url: string, altText: string) => {
        return (
            <Link href={url}>
                <div className={styles.image_div}>
                    <img className={styles.image} src={Gconfig.fashionpassS3Storage + imageUrl} alt={altText} />
                    <span className={styles.image_text}>{title}</span>
                </div>
            </Link>
        )
    }

    return (
        <>
            {
                featuredDataDesktop?.length > 0 &&
                <div className={styles.container}>
                    <h1 className={styles.main_heading}>Featured Brands</h1>
                    <MediaQuery minWidth={768}>
                        <div className={styles.image_container}>
                            {featuredDataDesktop[1] && imagesStyleDiv(featuredDataDesktop[0]?.imageUrl, featuredDataDesktop[0]?.title, featuredDataDesktop[0]?.link, featuredDataDesktop[0]?.altText)}
                            {featuredDataDesktop[0] && imagesStyleDiv(featuredDataDesktop[1]?.imageUrl, featuredDataDesktop[1]?.title, featuredDataDesktop[1]?.link, featuredDataDesktop[1]?.altText)}
                            {featuredDataDesktop[2] && imagesStyleDiv(featuredDataDesktop[2]?.imageUrl, featuredDataDesktop[2]?.title, featuredDataDesktop[2]?.link, featuredDataDesktop[2]?.altText)}
                        </div>
                    </MediaQuery>

                    <MediaQuery maxWidth={768}>
                        <Swiper
                            modules={[Navigation]}
                            slidesPerView={1.5}
                            centeredSlides={false}
                            initialSlide={0}
                            navigation={false}
                            spaceBetween={5}
                            className={styles.featured_carousel}
                            id="featured_brand_swiper"
                            simulateTouch={false}
                            loop={true}
                        >
                            {featuredDataMobile.map((item, index) => (
                                <SwiperSlide key={index} className={styles.featured_swiper_slide}>{imagesStyleDiv(item.imageUrl, item.title, item.link, item?.altText)}</SwiperSlide>
                            ))}
                        </Swiper>

                    </MediaQuery>
                </div>
            }
        </>
    );
};

export default FeaturedBrandsIndex;
