import React, { useState, useEffect } from 'react';
import 'styles/_fontsFamily.module.scss';
import styles from './holiday-favorites.module.scss';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { getHomePageSliderData } from 'components/shared/collectionAPIFunctions';
import { NextPage } from 'next';
import { DerivedAPI } from '@/constants/global-config';
import Link from 'next/link';
import { getSystemSettingsById } from 'components/shared/globalFunctions';
import WaveLoader from 'components/waveLoader';
import { useSelector } from 'react-redux';
import Gconfig from 'globalconfig';
import UpdatePrefrencePopup from 'components/shared/updatePrefrencesPopup';
import ProductsCarouselsIndex from '@/containers/products-carousels/products-carousels-index';
import NewPlanBanner from "@/containers/new-plans/new-plan-banner/new-plan-banner"

const HolidayFavorites: NextPage = () => {

  const [holidayFavoritesArr, setHolidayFavoritesArr] = useState([]);
  const [boards, setBoards] = useState<[]>([]);
  const [isSubscription, setIsSubscription] = useState(false);
  const customer = useAppSelector((state) => state.customer);
  const customerBoard = useAppSelector((state) => state.customerBoards);
  const [favLoader, setFavLoader] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0)
  const [isNewDataSet, setIsNewDataSet] = useState(false)
  const [isLoopAllowed, setIsLoopAllowed] = useState(false)
  const [favTitle, setFavTitle] = useState('')
  const systemSettings = useSelector((state) => state.systemSettings);
  const [openPrefecesPopup, setOpenPrefecesPopup] = useState(false)
  const dispatch = useAppDispatch();
  const [inCompleteProfile, _setInCompleteProfile] = useState(false)
  const [prefencesSize, setPrecencesSize] = useState('')


  const newUpdateFunc = (response: any) => {
    if (response.success && response?.profileValue) {
      setOpenPrefecesPopup(!openPrefecesPopup)
      let profileInfo = response?.profileValue;
      setHolidayFavoritesArr([])
      let size1 = profileInfo.dress_size.split('-')[0]
      let size2 = profileInfo.dress_size.split('-')[1]
      let size3 = profileInfo.jeans_size
      let size4 = profileInfo.top_size
      let sizes = `${size1},${size2},${size3},${size4}`
      setPrecencesSize(sizes)
    }

  }

  useEffect(() => {
    let dressSize: any = '', top_size = '', jeans_size = '', ds = ['', ''];
    let obj = {}
    let filterSizes = ''
    if (customer.customer != 'not_login' && customer.customer.has_subscription) {
      setFavLoader(true)
      customer.customer.tag_list.map((tag: any) => {
        if (tag.indexOf('Dress Size:') > -1) {
          dressSize = tag.split(':')
          if (dressSize && dressSize.length > 0) {
            ds[1] = dressSize[1] ? dressSize[1] : ''
            ds[0] = dressSize[2] ? dressSize[2] : ''
          }
        }

        if (tag.indexOf('Jeans Size:') > -1) {
          jeans_size = tag.split(':')[1]
        }

        if (tag.indexOf('Shirt & Top:') > -1) {
          top_size = tag.split(':')[1]
        }
      })

      obj = {
        dressSize: ds[0],
        dressNumber: ds[1],
        topSize: top_size,
        jeansSize: jeans_size,
      }
      if (top_size == '' || ds[0] == '' || ds[1] == '' || jeans_size == '') {
        filterSizes = ''
        _setInCompleteProfile(true)
      }
      else {
        filterSizes = `${ds[1]},${ds[0]},${top_size},${jeans_size}`

      }
      handleFetchNewArrivalData(filterSizes)

    }

    const element = document.getElementsByTagName("body")[0];
    element.addEventListener("touchmove", (e: any) => {
      try {
        if (e.target.className && e.target.className.indexOf('newBoard-tooltip') == -1 && e.target.className.indexOf('fa-heart') == -1 && e.target.className.indexOf('boardItem') == -1) {
          let boardsList = document.querySelectorAll('.newBoard-tooltip')
          boardsList.forEach((val, ind) => {
            if (boardsList[ind]) {
              boardsList[ind].style.display = "none"
            }
          })
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, [currentPage, prefencesSize])


  useEffect(() => {

    if (customer.customer != 'not_login' && customer.customer.has_subscription) {
      setIsSubscription(true);
    } else {
      setIsSubscription(false);
    }

    let boards: any = customerBoard.customerBoards;
    if (boards) {
      setBoards(boards);
    }
  }, [customer.customer, customerBoard.customerBoards]);

  const handlingTags = async () => {
    let systemsResponse = await getSystemSettingsById(Gconfig.system_settings.homeFavSliderTagId);
    if (systemsResponse && systemsResponse.success) {
      let setting_value = JSON?.parse(systemsResponse.system_settings.system_settings[0]?.setting_value)
      if (setting_value.favoritesCarouselTitle != "") {
        setFavTitle(setting_value.favoritesCarouselTitle)
        return setting_value.tags
      }
    }
  }

  const handleFetchNewArrivalData = async (filterSizes: string) => {
    if (customer.customer != 'not_login' && customer.customer.has_subscription) {
      setIsNewDataSet(false)
      if (holidayFavoritesArr?.length > 0) {
        setFavLoader(false)
      }
      else { setFavLoader(true) }
      let isLogin = false;
      let isAutoHide = 'true';
      if (customer.customer != 'not_login') {
        isLogin = true;
        if(customer.customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide='false'
      }

      const tags = await handlingTags()

      let query = ''
      if (tags != "" && tags != undefined) {
        query = DerivedAPI.COLLECTION_API_URL + `category_handles=all&filter_tags_any_list=|${tags}&filter_vendor_ids=&filter_sizes=${prefencesSize ? prefencesSize : filterSizes}&items_per_page=48&excludes=stats,categorylist,bump-photo&sort_by=fav_date&sort_order=desc&page=${currentPage}&show_hidden_items=2&in_stock=1&in_stock_sizes=1&other_filter=only_customer_favs&isprice_for_customer=true&exclude_tags=bump-photo`
      } else {
        query = DerivedAPI.COLLECTION_API_URL + `category_handles=all&filter_tags_any_list=&filter_vendor_ids=&filter_sizes=${prefencesSize ? prefencesSize : filterSizes}&items_per_page=48&excludes=stats,categorylist,bump-photo&sort_by=fav_date&sort_order=desc&page=${currentPage}&show_hidden_items=2&in_stock=1&in_stock_sizes=1&other_filter=only_customer_favs&isprice_for_customer=true&exclude_tags=bump-photo`
      }
      const response = await getHomePageSliderData(query, isLogin, 1, 'holidayFav',isAutoHide);  //holidayFavoritesArr
      const holidayFavoritesData = response && response.success && response.product_list.result_items?.length > 0 ? response.product_list.result_items : [];
      if (holidayFavoritesData && holidayFavoritesData.length > 0) {
        setFavLoader(false)

        setHolidayFavoritesArr(prevState => [...prevState, ...holidayFavoritesData]);
        if (holidayFavoritesData.length >= 48) {
          setIsNewDataSet(true)
          setIsLoopAllowed(false)
        } else {
          setIsLoopAllowed(true)
          setIsNewDataSet(false)
        }

      }
      else {
        setFavLoader(false)
      }
    }
  }

  function onSwiperSlideChange(e: any) {

    setCurrentSwiperSlide(e.activeIndex)
    setCurrentSwiperSlide(e.activeIndex)
    if (e.activeIndex >= holidayFavoritesArr?.length - 12) {
      if (isNewDataSet) {
        setCurrentPage(currentPage + 1)
      }
    }
  }

  return (
    <>
      {favLoader ? <div className={`${styles.container} ${styles.main_loading}`}><WaveLoader /></div> : (<>{holidayFavoritesArr?.length >= 6 && isSubscription ?
        <div className={`${styles.container} home-new-arrival ${isSubscription ? styles.custom_m_container : ''}`}>
          <div className={`${styles.title_div} ${isSubscription ? styles.custom_mt : ''}`}>
            <h2 className={styles.title}>
              {' '}
              {isSubscription && favTitle != '' && favTitle != undefined ? favTitle : 'Your Holiday Favorites'}
            </h2>
          </div>
          <div>
            {!inCompleteProfile ? <p className={`${styles.product_filters_applied}`}><span onClick={() => setOpenPrefecesPopup(!openPrefecesPopup)}>See what filters we applied</span></p> :
            <Link href='/my-account?tab=1' className={`${styles.product_filters_applied} d-flex justify-content-end`}><span onClick={() => setOpenPrefecesPopup(!openPrefecesPopup)}>See what filters we applied</span></Link>}
          </div>
          
          <ProductsCarouselsIndex sliderName={'favorite'} sliderData={holidayFavoritesArr} customerBoards={boards} isLoopAllowed={isLoopAllowed} onSwiperSlideChange={onSwiperSlideChange} currentSwiperSlide={currentSwiperSlide} swiperSliderName="holiday-favorite" />

          {!inCompleteProfile ? <p className={`${styles.product_filters_applied_mobile}`}><span onClick={() => setOpenPrefecesPopup(!openPrefecesPopup)}>See what filters we applied</span></p> :
            <Link href='/my-account?tab=1' className={`${styles.product_filters_applied_mobile}`} ><span onClick={() => setOpenPrefecesPopup(!openPrefecesPopup)}>See what filters we applied</span></Link>}
        </div> : ''}</>)}
      {inCompleteProfile == false ?
        openPrefecesPopup ? <UpdatePrefrencePopup isOpen={openPrefecesPopup} toggle={(val: boolean) => setOpenPrefecesPopup(val)} callBackFunc={newUpdateFunc} setInCompleteProfile={_setInCompleteProfile} btn_name='See Favorites' /> : "" : ''}
    </>
  );
};
export default HolidayFavorites;
